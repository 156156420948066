import React, { useState } from "react";
import { BsCameraFill } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { InputAdmin } from "../../../common/Input";
import ChangePasswordModal from "./modal/ChangePasswordModal";
import UseEditUserInfos from "../../Auteur/ProfilAuteur/requestUser/UseEditUserInfos";
import { useAppSelector } from "../../../../redux/hooks";
import { getAvatar, getInputAvatar } from "../../../../utils/Utils";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import { isAdminUser } from "../../../../routes/routerUtils";

function EditProfilAdmin() {
	const navigate = useNavigate();
	const { user } = useAppSelector((s) => s?.user);
	const {
		register,
		onSubmit,
		errors,
		avatar,
		handleChangeAvatar,
		isLoading,
		bio,
		handleChangeBio,
	} = UseEditUserInfos();
	const [modalChangePassword, setModalChangePassword] = useState(false);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	function openModalChangePassword(e: any) {
		e.preventDefault();
		setModalChangePassword(true);
	}
	return (
		<div className="profil-admin-component">
			<div className="container-profil-admin">
				<div className="card-profil-admin mb-3">
					<div className="container-info-user-profil">
						<form onSubmit={onSubmit}>
							<div className="content-pp-profil-admin text-center pb-3">
								<div className="d-flex justify-content-center align-items-center pb-4 mb-4 admin-user-avatar-container">
									<label htmlFor="input-test" className="input-testt">
										<img
											src={
												avatar
													? getInputAvatar(avatar)
													: getAvatar(user?.avatar)
											}
											alt="profil utilisateur"
											className="pp-profil-user-admin"
										/>
										<div className="admin-user-avatar-edit-hover">
											<BsCameraFill className="edit-avatar-camera-icon" />
										</div>
									</label>
									<input
										type="file"
										className="input-add-avatar"
										id="input-test"
										accept="image/*"
										onChange={(e) => handleChangeAvatar(e)}
									/>
								</div>
							</div>
							<div className="formulaire-midifier-profile-container">
								<div className="row">
									<div className="col-md-6">
										<InputAdmin
											type="text"
											label="Nom"
											id="nom"
											required
											placeholder="Nom"
											{...register("last_name")}
											error={errors?.last_name?.message}
										/>
									</div>
									<div className="col-md-6">
										<InputAdmin
											type="text"
											label="Prénom"
											id="prenom"
											required
											placeholder="Prénom"
											{...register("first_name")}
											error={errors?.first_name?.message}
										/>
									</div>

									<div className="col-md-6">
										<InputAdmin
											type="email"
											label="Adresse email"
											id="adresse_email"
											required
											placeholder="Email"
											{...register("email")}
											error={errors?.email?.message}
										/>
									</div>
									{!!isAdminUser(user) && (
										<div className="col-md-6">
											<InputAdmin
												type="tel"
												label="Télépone"
												id="telepone"
												required
												placeholder="Télépone"
												{...register("phone")}
												error={errors?.phone?.message}
											/>
										</div>
									)}
									<div className="col-md-6">
										<InputAdmin
											type="text"
											label="Adresse"
											id="adresse"
											placeholder="Adresse"
											{...register("adress")}
											error={errors?.adress?.message}
										/>
									</div>
									<div className="col-md-12">
										<div className="mb-3">
											<label
												htmlFor="nom"
												className="form-label form-label-admin"
											>
												Bio
											</label>
											{/* <textarea
                        className="form-control form-control-admin"
                        rows={5}
                      >
                        Bio
                      </textarea> */}
											<ReactQuill
												theme="snow"
												value={bio}
												onChange={handleChangeBio}
												className="profil-bio"
											/>
										</div>
									</div>
									<div className="col-md-12 text-end">
										<div className="row">
											<div className="col-lg-4 offset-lg-4 col-md-6 mb-3 pt-4">
												<div className="content-btn-form-admin">
													<button
														className="btn auth-submit-annuler w-100"
														type="button"
														onClick={() => navigate(-1)}
													>
														Annuler
													</button>
												</div>
											</div>
											<div className="col-lg-4 col-md-6 mb-3 pt-4">
												<div className="content-btn-form-admin">
													<button
														className="btn btn-page-title-admin w-100"
														disabled={isLoading}
													>
														{!!isLoading ? (
															<>
																<span
																	className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
																	role="status"
																></span>
																<span>Enregistrement...</span>
															</>
														) : (
															"Enregistrer"
														)}
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>

								{/* <div className="info-user-profil-btn-edit-container">
                  <button className="btn btn-edir-profil" disabled={isLoading}>
                    {!!isLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
                          role="status"
                        ></span>
                        <span>Enregistrement...</span>
                      </>
                    ) : (
                      "Enregistrer"
                    )}
                  </button>
                </div> */}
							</div>
						</form>

						<div className="admin-edit-profil-param-main-page-container py-4">
							<div className="d-flex justify-content-between">
								<InputAdmin
									type="password"
									label="Mot de passe"
									id="password"
									placeholder="****************"
								/>
								<button
									className="btn admin-edit-profil-parametre-header-button"
									onClick={openModalChangePassword}
								>
									<FiEdit className="admin-edit-profil-parametre-header-icon" />{" "}
									Changer de mot de passe
								</button>
							</div>
							<ChangePasswordModal
								modalChangePassword={modalChangePassword}
								setModalChangePassword={setModalChangePassword}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default EditProfilAdmin;
