import React from 'react'
import CommandeTextTable from './table/CommandeTextTable';

function CommandeTextManager() {
  return (
		<div className="list-admin-admin">
			<div className="container-title-page-admin">
				<div className="content-title-admin">
					<h3 className="h3-title-admin">Commande de texte</h3>
				</div>
			</div>
			<div className="container-admin-page-table admin-table mt-4">
				<CommandeTextTable />
			</div>
		</div>
  );
}

export default CommandeTextManager