type ENV = "dev" | "prod" | "demo";

export const currentEnv: ENV = "prod";

const env: ENV = currentEnv;

export const Env = env;

const API_DEV_URL = "https://back.yeewuna.withvolkeno.com";
const API_DEMO_URL = "https://back.editionsyeewuna.com";
const API_PROD_URL = "https://back.editionsyeewuna.com";

export const APP_URL_DEV = "https://yeewuna-app.withvolkeno.com";
export const APP_URL_DEMO = " https://editionsyeewuna.com";
export const APP_URL_PROD = " https://editionsyeewuna.com";

function processApiUrl() {
	if (env === "prod") return API_PROD_URL;
	if (env === "demo") return API_DEMO_URL;
	return API_DEV_URL;
}

function processAppUrl() {
	if (env === "prod") return APP_URL_PROD;
	if (env === "demo") return APP_URL_DEMO;
	return APP_URL_DEV;
}
export const ApiBaseUrl = processApiUrl();
export const AppBaseUrl = processAppUrl();

export const IN_TECH_API_KEY = "EC90A2CF-8676-4268-B149-EEE12C5A3058";
export const APPLE_CLIENT_ID = "45Q958F34T";
export const GoogleClientId =
	"663428554052-h5rkohv4cs60sgou37e0rvhntgbth77f.apps.googleusercontent.com";
