import Login from "../components/auth/Login";
import HomePage from "../components/HomePage/HomePage";
import MentionLegale from "../components/shared/FooterPage/MentionLegale";
import Help from "../components/shared/FooterPage/Help";
import Cgu from "../components/shared/FooterPage/Cgu";
import QuiSommesNous from "../components/HomePage/QuiSommesNous/QuiSommesNous";
import Faq from "../components/HomePage/Faq/Faq";
import Services from "../components/HomePage/Services/Services";
import Blog from "../components/HomePage/Blog/Blog";
import DevenirAutheur from "../components/HomePage/SoumettreOuvrages/DevenirAutheur";
import { Navigate } from "react-router-dom";
import DashboardAdmin from "../components/TableauDeBord/Admin/DashboardAdmin/DashboardAdmin";
import AccueilUtilisateur from "../components/TableauDeBord/Utilisateur/Accueil/AccueilUtilisateur";
import AuteurUtilisateur from "../components/TableauDeBord/Utilisateur/Autheurs/AuteurUtilisateur";
import Register from "../components/auth/Register";
import ForgetPassword from "../components/auth/ForgetPassword";
import ConfirmMailMessage from "../components/auth/ConfirmMailMessage";
import UserLayout from "../components/TableauDeBord/Utilisateur/NavbarUser/UserLayout/UserLayout";
import LayoutAdmin from "../components/TableauDeBord/Admin/LayoutAdmin/LayoutAdmin";
import DetailsAuteur from "../components/TableauDeBord/Utilisateur/Autheurs/DetailsAuteur";
import GestionAuteurs from "../components/TableauDeBord/Admin/GestionAuteurs/GestionAuteurs";
import AjouterAuteur from "../components/TableauDeBord/Admin/GestionAuteurs/AjouterAuteur";
import GestionUsers from "../components/TableauDeBord/Admin/GestionUsers/GestionUsers";
import AjouterUser from "../components/TableauDeBord/Admin/GestionUsers/AjouterUser";
import UserDetail from "../components/TableauDeBord/Admin/GestionUsers/UserDetail";
import GestionLivre from "../components/TableauDeBord/Admin/GestionLivre/GestionLivre";
import Bibliotheque from "../components/TableauDeBord/Utilisateur/Bibliotheque/Bibliotheque";
import Communaute from "../components/TableauDeBord/Utilisateur/Communaute/Communaute";
import Panier from "../components/TableauDeBord/Utilisateur/Panier/Panier";
import MonProfile from "../components/TableauDeBord/Utilisateur/MonProfile/MonProfile";
import DetailsActualite from "../components/TableauDeBord/Utilisateur/Autheurs/AuteurTabs/DetailsActualite";
import DescriptionLivre from "../components/TableauDeBord/Utilisateur/Bibliotheque/DescriptionLivre";
import DetailLivre from "../components/TableauDeBord/Admin/GestionLivre/DetailLivre";
import Messagerie from "../components/TableauDeBord/Admin/Messagerie/Messagerie";
import ProfilAdmin from "../components/TableauDeBord/Admin/ProfilAdmin/ProfilAdmin";
import EditProfilAdmin from "../components/TableauDeBord/Admin/ProfilAdmin/EditProfilAdmin";
import AddCguAdmin from "../components/TableauDeBord/Admin/ParametresAdmin/AddCguAdmin";
import AddPolicyAdmin from "../components/TableauDeBord/Admin/ParametresAdmin/AddPolicyAdmin";
import ParametresAdmin from "../components/TableauDeBord/Admin/ParametresAdmin/ParametresAdmin";
import DashboardAuteur from "../components/TableauDeBord/Auteur/DashboardAuteur/DashboardAuteur";
import GestionLivreAuteur from "../components/TableauDeBord/Auteur/GestionLivreAuteur/GestionLivreAuteur";
import AjouterLivre from "../components/TableauDeBord/Auteur/GestionLivreAuteur/AjouterLivre";
import GestionPayment from "../components/TableauDeBord/Auteur/GestionPayment/GestionPayment";
import ProfilAuteur from "../components/TableauDeBord/Auteur/ProfilAuteur/ProfilAuteur";
import DetailsDiscussion from "../components/TableauDeBord/Utilisateur/Communaute/DetailsDiscussion";
import AjouterPublication from "../components/TableauDeBord/Utilisateur/Communaute/AjouterPublication";
import EditProfilAuteur from "../components/TableauDeBord/Auteur/ProfilAuteur/EditProfilAuteur";
import DetailsLivre from "../components/TableauDeBord/Utilisateur/Bibliotheque/DetailsLivre";
import LectureLivre from "../components/TableauDeBord/Utilisateur/Bibliotheque/LectureLivre";
import { UserType } from "../utils/api/user/user.type";
import ResetPassword from "../components/auth/ResetPassword";
import AddOffer from "../components/TableauDeBord/Admin/ParametresAdmin/ParametreTabs/AddOffer";
import { AutheurRoutes, ProtectedRoutes, RedirectAuthRoute, VisiteurRoutes } from "./routerUtils";
import CategorieList from "../components/TableauDeBord/Admin/Faq/Devis";
import GestionArticles from "../components/TableauDeBord/Auteur/GestionActualites/GestionArticles";
import AjoutArticle from "../components/TableauDeBord/Auteur/GestionActualites/AjoutArticle";
import EditArticle from "../components/TableauDeBord/Auteur/GestionActualites/EditArticle";
import ListesdesAuteurs from "../components/TableauDeBord/Auteur/ListesdesAuteurs/ListesdesAuteurs";
import GestionArticlesAdmin from "../components/TableauDeBord/Admin/Blog/GestionArticlesAdmin";
import DetailBlog from "../components/HomePage/Blog/DetailBlog";
import FaqAdmin from "../components/TableauDeBord/Admin/Faq/FaqAdmin";
import Notifications from "../components/TableauDeBord/Admin/Notification/Notifications";
import Devis from "../components/TableauDeBord/Admin/devis/Devis";
import LectureAudio from "../components/TableauDeBord/Utilisateur/Bibliotheque/LectureAudio";
import BuyCartUser from "../components/TableauDeBord/Utilisateur/Panier/BuyCartUser";
import SupportManager from "../components/TableauDeBord/Admin/Support/SupportManager";
import CommandeTextManager from "../components/TableauDeBord/Admin/CommandeText/CommandeTextManager";

export const AppRoutes = [
	{
		path: "/connexion",
		element: (
			<RedirectAuthRoute>
				<Login />
			</RedirectAuthRoute>
		),
	},
	{
		path: "/inscription",
		element: (
			<RedirectAuthRoute>
				<Register />
			</RedirectAuthRoute>
		),
	},
	{
		path: "/mot-de-passe-oublie",
		element: <ForgetPassword />,
	},
	{
		path: "/message-de-confirmation",
		element: <ConfirmMailMessage />,
	},
	{
		path: "/modifier-mot-de-passe",
		element: <ResetPassword />,
	},
	{
		path: "/",
		element: <HomePage />,
	},
	{
		path: "/mentions-legales",
		element: <MentionLegale />,
	},
	{
		path: "/aide",
		element: <Help />,
	},
	{
		path: "/cgu",
		element: <Cgu />,
	},
	{
		path: "/qui-sommes-nous",
		element: <QuiSommesNous />,
	},
	{
		path: "/faq",
		element: <Faq />,
	},
	{
		path: "/services",
		element: <Services />,
	},
	{
		path: "/blog",
		element: <Blog />,
	},
	{
		path: "/blog/:slug",
		element: <DetailBlog />,
	},
	{
		path: "/devenir-autheur",
		element: <DevenirAutheur />,
	},
	{
		path: "admin/",
		element: (
			<ProtectedRoutes userType={[UserType.admin, UserType.superAdmin]}>
				<LayoutAdmin />
			</ProtectedRoutes>
		),
		children: [
			{ element: <Navigate to="/admin/dashboard" replace />, index: true },
			{
				path: "dashboard",
				element: <DashboardAdmin />,
			},
			{
				path: "auteurs",
				element: <GestionAuteurs />,
			},
			{
				path: "ajouter-auteur",
				element: <AjouterAuteur />,
			},
			{
				path: "modifier-auteur/:slug",
				element: <AjouterAuteur />,
			},
			{
				path: "utilisateurs",
				element: <GestionUsers />,
			},
			{
				path: "ajouter-utilisateur",
				element: <AjouterUser />,
			},
			{
				path: "modifier-utilisateur/:slug",
				element: <AjouterUser />,
			},
			{
				path: "utilisateur/:slug",
				element: <UserDetail />,
			},
			{
				path: "livres",
				element: <GestionLivre />,
			},
			{
				path: "livre/:slug",
				element: <DetailLivre />,
			},
			{
				path: "messages",
				element: <Messagerie />,
			},
			{
				path: "profil",
				element: <ProfilAdmin />,
			},
			{
				path: "modifier-profil",
				element: <EditProfilAdmin />,
			},
			{
				path: "parametres",
				element: <ParametresAdmin />,
			},
			{
				path: "parametres/abonnements/ajouter",
				element: <AddOffer />,
			},
			{
				path: "parametres/abonnements/:slug/modifier",
				element: <AddOffer />,
			},
			{
				path: "ajouter-cgu",
				element: <AddCguAdmin />,
			},
			{
				path: "ajouter-politique-de-confidentialite",
				element: <AddPolicyAdmin />,
			},
			{
				path: "categories",
				element: <CategorieList />,
			},
			{
				path: "blog",
				element: <GestionArticlesAdmin />,
			},
			{
				path: "blog/ajouter",
				element: <AjoutArticle />,
			},
			{
				path: "blog/:slug/modifier",
				element: <EditArticle />,
			},
			{
				path: "blog/:slug",
				element: <DetailsActualite />,
			},
			{
				path: "faq",
				element: <FaqAdmin />,
			},
			{
				path: "notifications",
				element: <Notifications />,
			},
			{
				path: "devis",
				element: <Devis />,
			},
			{
				path: "support",
				element: <SupportManager />,
			},
			{
				path: "commande-texte",
				element: <CommandeTextManager />,
			},
		],
	},
	{
		path: "utilisateur/",
		element: (
			<VisiteurRoutes>
				<UserLayout />
			</VisiteurRoutes>
		),
		children: [
			{ element: <Navigate to="/utilisateur/accueil" replace />, index: true },
			{
				path: "accueil",
				element: <AccueilUtilisateur />,
			},
			{
				path: "auteurs",
				element: <AuteurUtilisateur />,
			},
			{
				path: "actualite/:slug",
				element: <DetailsActualite />,
			},
			{
				path: "auteur/:slug",
				element: <DetailsAuteur />,
			},
			{
				path: "bibliotheque",
				element: <Bibliotheque />,
			},
			{
				path: "bibliotheque/livre/:slug",
				element: <DescriptionLivre />,
			},
			{
				path: "bibliotheque/details-livre/:slug",
				element: <DetailsLivre />,
			},
			{
				path: "bibliotheque/livre-audio/:slug",
				element: <LectureAudio />,
			},
			{
				path: "communaute",
				element: <Communaute />,
			},
			{
				path: "user-profil",
				element: <ProfilAdmin />,
			},
			{
				path: "communaute/discussion/:slug",
				element: <DetailsDiscussion />,
			},
			{
				path: "panier",
				element: <Panier />,
			},
			{
				path: "remplir-information-complementaire",
				element: <BuyCartUser />,
			},
			// {
			// 	path: "remplir-information-complementaire/paiement",
			// 	element: <BuyCartUser />,
			// },
			{
				path: "livre/:slug",
				element: <LectureLivre />,
			},
			{
				path: "messages",
				element: <Messagerie />,
			},
			{
				path: "mon-profil",
				element: <MonProfile />,
			},
			{
				path: "modifier-profil",
				element: <EditProfilAdmin />,
			},
			{
				path: "ajouter-publication",
				element: <AjouterPublication />,
			},
			{
				path: "notifications",
				element: <Notifications />,
			},
		],
	},
	{
		path: "auteur/",
		element: (
			<AutheurRoutes>
				<LayoutAdmin />
			</AutheurRoutes>
		),
		children: [
			{ element: <Navigate to="/auteur/accueil" replace />, index: true },
			{
				path: "accueil",
				element: <DashboardAuteur />,
			},
			{
				path: "livres",
				element: <GestionLivreAuteur />,
			},
			{
				path: "ajouter-livre",
				element: <AjouterLivre />,
			},
			{
				path: "modifier-livre/:slug",
				element: <AjouterLivre />,
			},
			{
				path: "messages",
				element: <Messagerie />,
			},
			{
				path: "profil",
				element: <ProfilAuteur />,
			},
			{
				path: "modifier-profil",
				element: <EditProfilAuteur />,
			},
			{
				path: "paiements",
				element: <GestionPayment />,
			},
			{
				path: "articles",
				element: <GestionArticles />,
			},
			{
				path: "articles/ajouter",
				element: <AjoutArticle />,
			},
			{
				path: "articles/:slug/modifier",
				element: <EditArticle />,
			},
			{
				path: "articles/:slug",
				element: <DetailsActualite />,
			},
			{
				path: "liste-des-auteurs",
				element: <ListesdesAuteurs />,
			},
			{
				path: "liste-des-auteurs/:slug",
				element: <DetailsAuteur />,
			},
			{
				path: "notifications",
				element: <Notifications />,
			},
		],
	},
];
