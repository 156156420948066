import React from "react";
import DevisTable from "../devis/DevisTable";

const CategorieList = () => {
	return (
		<div className="list-admin-admin">
			<div className="container-title-page-admin">
				<div className="content-title-admin">
					<h3 className="h3-title-admin">Catégories</h3>
				</div>
			</div>
			<DevisTable />
		</div>
	);
};

export default CategorieList;
