import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import TableSkeleton from "../../../common/TableSkeleton";
import { useDelete, useModal, usePagination } from "../../../../utils/hooks";
import { getImage, isShowPagination } from "../../../../utils/Utils";
import { CustomPagination } from "../../Auteur/GestionLivreAuteur/ListLivresAuteurTable/ListLivresAuteurTable";
import AddMembreModal from "./modal/AddMembreModal";
import { ButtonAdd, ButtonDelete, ButtonEdit } from "../../../common/Button";
import { ITeam } from "../../../../utils/api/team/team.type";
import { useDeleteTeamMutation, useGetTeamQuery } from "../../../../utils/api/team/team.api";

function DeleteMembre({ item }: { item: ITeam }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteTeamMutation();
	const onDelete = useDelete<ITeam>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cette offre?",
		successMessage: "Offre supprimée",
	});
	return <ButtonDelete onClick={onDelete} />;
}

function Teams() {
	const { page, setPage, perPage, setPerPage } = usePagination(10);
	const { data, isLoading } = useGetTeamQuery({
		page,
		limit: perPage,
	});
	const { isShowModal, closeModal, openModal, openEditModal, item } = useModal<ITeam>();

	console.log({ data });

	const actionFormatter = (cell: string, row: ITeam) => {
		return (
			<div className="table-actions-btn-container-commandes d-flex gap-3">
				<ButtonEdit onClick={() => openEditModal(row)} />
				<DeleteMembre item={row} />
			</div>
		);
	};
	const imgFormatter = (cell: string, row: ITeam) => {
		return (
			<div className="table-actions-btn-container-img gap-2 d-flex align-items-center">
				<div className="content-img-pp-livre-admin-table">
					<img src={getImage(cell)} alt={row?.name} width={50} />
				</div>{" "}
			</div>
		);
	};

	const columns = [
		{
			dataField: "image",
			text: "Image",
			formatter: (cell: string, row: ITeam) => imgFormatter(cell, row),
		},
		{
			dataField: "name",
			text: "Nom",
		},
		{
			dataField: "function",
			text: "Function",
		},
		{
			dataField: "id",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: ITeam) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			<div className="content-btn-action-title-page-admin flex-r mb-5">
				<ButtonAdd label="Ajouter un membre" onClick={openModal} />
			</div>
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => "Aucune donnée trouvée"}
					/>
					{isShowPagination(data?.count || 0, perPage) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, perPage) => {
								setPerPage(perPage);
								setPage(page);
							}}
							perPage={perPage}
						/>
					)}
				</>
			)}
			<AddMembreModal isOpen={isShowModal} closeModal={closeModal} item={item} />
		</>
	);
}

export default Teams;
