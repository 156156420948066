import React from "react";
import "./GestionLivre.css";
import ListLivresTable from "./ListLivresTable/ListLivresTable";

const GestionLivre = () => {
	return (
		<div className="list-admin-admin">
			<div className="container-title-page-admin">
				<div className="content-title-admin">
					<h3 className="h3-title-admin">Livres</h3>
				</div>
			</div>
			<div className="container-admin-page-table admin-table mt-4">
				<ListLivresTable />
			</div>
		</div>
	);
};

export default GestionLivre;
