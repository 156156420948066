import React from "react";
import { MdOutlineAdd } from "react-icons/md";
import { useToggleModal } from "../../../../utils/hooks";
import { AlertInfo } from "../../../common/Alert";
import DetailMessageTabsAdmin from "./DetailMessageTabsAdmin";
import useMessagerie from "./hooks/useMessagerie";
import "./Messagerie.css";
import NewChatModal from "./modal/NewChatModal";
import SommaireItem, { DiscussionItemSkeleton } from "./SommaireItem";

const Messagerie = () => {
	const { isShowModal, closeModal, openModal } = useToggleModal();
	const {
		chatData,
		isLoading,
		selectedChat,
		setSelectedUser,
		selectedUser,
		handleReadAll,
		getNotReadedChat,
		resetFilter,
		read,
		setSearch,
	} = useMessagerie();
	return (
		<div className="list-admin-admin">
			<div className="container-title-page-admin">
				<div className="content-title-admin">
					<h3 className="h3-title-admin">Messages</h3>
				</div>
				<div className="content-btn-action-title-page-admin">
					<button className="btn btn-page-title-admin" onClick={openModal}>
						<MdOutlineAdd />
						<span className="ps-2">Nouvelle discussion</span>
					</button>
					<NewChatModal
						isOpen={isShowModal}
						closeModal={closeModal}
						onSelect={setSelectedUser}
					/>
				</div>
			</div>
			<div className="tabs-component-container">
				<div className="users-network-connect-list mt-4">
					<div className="container-btn-filter-message mb-3">
						<div className="content-btn-filter-message">
							<button className="btn btn-filter-message active" onClick={resetFilter}>
								Tout
							</button>
						</div>
						<div className="content-btn-filter-message">
							<button className="btn btn-filter-message" onClick={getNotReadedChat}>
								Non lus
							</button>
						</div>
						<div className="content-btn-filter-message">
							<button className="btn btn-filter-message" onClick={handleReadAll}>
								Marquer tout comme lu
							</button>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4">
							<div className="bloc-search-message mb-3 position-relative">
								<form>
									<input
										className="form-control"
										type="search"
										placeholder="Rechercher"
										onChange={(e) => setSearch(e.target.value)}
									/>
									<button type="submit" value="search">
										<i className="fa fa-search" aria-hidden="true"></i>
									</button>
								</form>
							</div>
							<div className="parent-table-container-message-tabs custom-parent-table-container-message-tabs">
								<div>
									<p className="titre-messages-bloc ps-3 pb-3">Discussions</p>
								</div>
								<ul className="list-group list-group-message list-group-flush">
									{isLoading && <DiscussionItemSkeleton />}
									{!isLoading && chatData?.length === 0 && (
										<AlertInfo
											message={
												read !== null
													? "Aucune discussion ne correspond à votre recherche"
													: "Aucune discussion ouverte"
											}
											size="small"
										/>
									)}
									{!isLoading &&
										chatData?.map((chat) => (
											<SommaireItem
												key={chat?.user?.slug}
												item={chat}
												active={selectedChat === chat}
												onClick={() => setSelectedUser(chat.user)}
											/>
										))}
								</ul>
							</div>
						</div>
						<div className="col-md-8">
							<DetailMessageTabsAdmin chat={selectedChat} user={selectedUser} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Messagerie;
