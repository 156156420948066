import "./Login.css";
import Logo from "../../assets/appImages/logo.png";
import { NavLink } from "react-router-dom";
import ImgLogin from "../../assets/appImages/login-img.png";
import useRegisterForm, { usePasswordRule } from "./useAuthForm/useRegisterForm";
import ErrorMessage from "../common/ErrorMessage";
import { RiEyeLine } from "react-icons/ri";
import { AiFillEyeInvisible } from "react-icons/ai";
import { PassWordRuleView } from "./PasswordRuleView";

const Register = () => {
	const { register, onSubmit, setValue, errors, isLoading } = useRegisterForm();

	const {
		haveMinCharacter,
		haveMinNumber,
		haveMinLowercase,
		haveMinSpecialCharacter,
		haveMinUppercase,
		handleChangePassword,
		isShowPasswordRules,
		showPasswordRule,
		showPassword,
		toggleShowPassword,
	} = usePasswordRule(setValue);

	const props = {
		haveMinCharacter,
		haveMinNumber,
		haveMinLowercase,
		haveMinSpecialCharacter,
		haveMinUppercase,
	};

	return (
		<div>
			<div className="container-page-login-yeewuna">
				<div className="auth-row row">
					<div className="col-md-6 auth-col auth-left-side-col d-flex">
						<div className="auth-left-side-container w-100">
							<img src={ImgLogin} alt="connexion" className="img-login" />
						</div>
					</div>
					<div className="col-md-6 auth-col auth-right-side-col d-flex">
						<div className="auth-right-side-container w-100">
							<div className="auth-form-container">
								<div className="container-img-logo pb-4 text-center">
									<img src={Logo} alt="Logo" className="img-logo-login" />
								</div>
								<h1 className="auth-form-title">Bienvenue</h1>
								<p className="text-message-login">Inscrivez-vous</p>
								<div className="mt-4">
									<form id="auth-form" onSubmit={onSubmit}>
										<div className="row auth-form-row">
											<div className="col-md-12 auth-input-col mb-3">
												<div className="auth-form-group">
													<label className="form-label form-label-auth-login">
														Prénom
														<span
															className="text-danger"
															style={{ fontSize: 18 }}
														>
															*
														</span>
													</label>
													<input
														type={`text`}
														className="form-control auth-form-control"
														id="prenom"
														placeholder="Prénom"
														aria-label="prenom"
														{...register("first_name")}
													/>
													{errors?.first_name && (
														<ErrorMessage
															message={errors?.first_name?.message}
														/>
													)}
												</div>
											</div>
											<div className="col-md-12 auth-input-col mb-3">
												<div className="auth-form-group">
													<label className="form-label form-label-auth-login">
														Nom
														<span
															className="text-danger"
															style={{ fontSize: 18 }}
														>
															*
														</span>
													</label>
													<input
														type={`text`}
														className="form-control auth-form-control"
														id="nom"
														placeholder="Nom"
														aria-label="nom"
														{...register("last_name")}
													/>
													{errors?.last_name && (
														<ErrorMessage
															message={errors?.last_name?.message}
														/>
													)}
												</div>
											</div>
											<div className="col-md-12 auth-input-col mb-3">
												<div className="auth-form-group">
													<label className="form-label form-label-auth-login">
														Email
														<span
															className="text-danger"
															style={{ fontSize: 18 }}
														>
															*
														</span>
													</label>
													<input
														type={`email`}
														className="form-control auth-form-control"
														id="email-or-username"
														placeholder="Email"
														aria-label="Username"
														{...register("email")}
													/>
													{errors?.email && (
														<ErrorMessage
															message={errors?.email?.message}
														/>
													)}
												</div>
											</div>
											{/* <div className="col-md-12 auth-input-col mb-3">
												<div className="auth-form-group">
													<label className="form-label form-label-auth-login">
														Téléphone
														<span
															className="text-danger"
															style={{ fontSize: 18 }}
														>
															*
														</span>
													</label>
													<input
														type={`text`}
														className="form-control auth-form-control"
														id="phone"
														placeholder="Téléphone"
														aria-label="Phone"
														{...register("phone")}
													/>
													{errors?.phone && (
														<ErrorMessage
															message={errors?.phone?.message}
														/>
													)}
												</div>
											</div> */}
											<div className="col-md-12 auth-input-col mb-2">
												<div className="auth-form-group position-relative">
													<label className="form-label form-label-auth-login">
														Mot de passe
														<span
															className="text-danger"
															style={{ fontSize: 18 }}
														>
															*
														</span>
													</label>
													<input
														type={`${
															showPassword ? "text" : "password"
														}`}
														className="form-control auth-form-control"
														id="password"
														placeholder="Mot de passe"
														aria-label="Password"
														{...register("password")}
														onChange={handleChangePassword}
														onFocus={showPasswordRule}
														onBlur={showPasswordRule}
													/>
													<span
														className="span-eye-password-log"
														onClick={toggleShowPassword}
													>
														{!showPassword ? (
															<RiEyeLine />
														) : (
															<AiFillEyeInvisible />
														)}
													</span>
												</div>
												{errors?.password && (
													<ErrorMessage
														message={errors?.password?.message}
													/>
												)}
											</div>
											{/* ============= Validation password ============= */}

											{isShowPasswordRules && <PassWordRuleView {...props} />}

											<div className="col-md-12 auth-input-col mb-2">
												<div className="auth-form-group">
													<label className="form-label form-label-auth-login">
														Confirmer mot de passe
														<span
															className="text-danger"
															style={{ fontSize: 18 }}
														>
															*
														</span>
													</label>
													<input
														type="password"
														className="form-control auth-form-control"
														id="confirm_password"
														placeholder="Confirmer mot de passe"
														aria-label="Password"
														{...register("confirmPassword")}
													/>
													{errors?.confirmPassword && (
														<ErrorMessage
															message={
																errors?.confirmPassword?.message
															}
														/>
													)}
												</div>
											</div>
											{/* <div className="d-flex my-3 gap-3">
                        <div className="content-switch-register">
                          <label
                            htmlFor="with-warrenty"
                            className="switch-container"
                          >
                            <input hidden type="checkbox" id="with-warrenty" />
                            <div className="switch-bg"></div>
                            <div className="round-box"></div>
                            <div className="switch-left">
                              <span>Non</span>
                            </div>
                            <div className="switch-right">
                              <span>Oui</span>
                            </div>
                          </label>
                        </div>
                        <div>
                          <p className="text-se-souvenir-de-moi mb-0">
                            Se souvenir de moi
                          </p>
                        </div>
                      </div> */}
											<div className="col-md-12 auth-submit-btn-container mt-4">
												{!isLoading ? (
													<button
														type="submit"
														className="btn auth-submit-btn trans-0-2 w-100"
														disabled={isLoading}
													>
														Inscription
													</button>
												) : (
													<button
														type="submit"
														className="btn auth-submit-btn trans-0-2 w-100"
													>
														<span
															className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
															role="status"
														></span>
														<span>Chargement...</span>
													</button>
												)}
											</div>
										</div>
										<div className="content-text-message-login mt-3 text-center">
											<span className="auth-form-message">Déjà inscris?</span>
											<NavLink
												to="/connexion"
												className="btn-text-message-login ps-2"
											>
												Connectez - vous
											</NavLink>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Register;
