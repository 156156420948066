/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import { useBookFromLocation } from "../../../../utils/api/book/book.api";
import { createMarkup, getImage } from "../../../../utils/Utils";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { ApiBaseUrl } from "../../../../utils/http";
import BuyBookModal from "./BuyBookModal";
import { useToggleModal } from "../../../../utils/hooks";
import { useNavigate } from "react-router-dom";

function LectureAudio() {
	const [item] = useBookFromLocation();
	const { closeModal, isShowModal, openModal } = useToggleModal();
	const player = useRef<any>();
	const [isPlay, setIsPlay] = useState<boolean>(false);
	const navigate = useNavigate();

	useEffect(() => {
		let timeoutId;
		if (!item?.audiobook) {
			if (isPlay) {
				timeoutId = setTimeout(() => {
					openModal();
				}, 60000);
			}
		}
		return () => {
			clearTimeout(timeoutId);
		};
	}, [item?.audiobook, isPlay]);
	useEffect(() => {
		if (isShowModal) {
			setTimeout(() => {
				navigate("/utilisateur/accueil");
				closeModal();
			}, 5000);
		}
	}, [isShowModal]);
	return (
		<div className="user-dash-page-content-container pb-5 mb-3">
			<div className="yeewuna-details-actu-container px-0 px-xl-5">
				<div className="d-flex justify-content-center">
					<img
						src={getImage(item?.couverture)}
						alt={item?.title}
						width="100px"
						height="140px"
						style={{ objectFit: "cover" }}
					/>
				</div>
				<h1 className="audio-livre-title">{item?.title}</h1>
				<div className="my-3">
					<div
						className="yeewuna-description-livre-infos-text-paragraphe-apropos"
						dangerouslySetInnerHTML={createMarkup(item?.description)}
					/>
				</div>
				<div className="audio-lecture">
					<AudioPlayer
						src={ApiBaseUrl + item?.audio}
						ref={player}
						onPlay={() => setIsPlay(true)}
					/>
				</div>
			</div>
			<BuyBookModal item={item} isOpen={isShowModal} closeModal={closeModal} />
		</div>
	);
}

export default LectureAudio;
