import React from "react";
import { ActuItem } from "./AuteurTabs/FilActualite";
import { Link, useLocation } from "react-router-dom";
import {
	createMarkup,
	formatFollowerNumber,
	getAvatar,
	getImage,
	getName,
} from "../../../../utils/Utils";
import LivreItem from "../Accueil/LivreItem";
import { BsFillHeartFill } from "react-icons/bs";
import { AlertInfo } from "../../../common/Alert";
import { useAuthor } from "./hooks/useAuthor";
import { Toaster } from "react-hot-toast";

function DetailsAuteur() {
	const { item, articles, handleToggleFollow, isFollow } = useAuthor();
	let location = useLocation();
	return (
		<div className="yeewuna-parcours-utilisateur-liste-auteur-container my-3">
			<div className="yeewuna-detail-auteur-titre">Détail de l’auteur</div>
			<div className="dashbord-visiteur-component">
				<div className="yeewuna-dash-utilisateur-page-content-container mb-3">
					<div className="yeewuna-detail-auteur-img-banniere-container">
						{item?.banniere ? (
							<img
								src={getImage(item?.banniere)}
								alt="photos de banniere"
								className="yeewuna-detail-auteur-img-banniere"
							/>
						) : (
							<div className="banner-user"></div>
						)}
						<div className="yeewuna-detail-auteur-img-profile-container">
							<img
								src={getAvatar(item?.avatar)}
								alt="photos de l'autheur"
								className="yeewuna-detail-auteur-img-profile"
							/>
						</div>
					</div>
					<div className="yeewuna-detail-auteur-page-container">
						<div className="yeewuna-detail-auteur-nom-autheur-container pt-3">
							<div className="yeewuna-detail-auteur-nom-autheur">{getName(item)}</div>
							<div className="yeewuna-detail-auteur-statistic-autheur-container">
								<div className="yeewuna-detail-auteur-statistic-autheur">
									<div className="yeewuna-detail-auteur-statistic-text">
										<span>{item?.published_books?.length}</span> oeuvres
									</div>
									<div className="yeewuna-detail-auteur-statistic-text">
										<span>
											{formatFollowerNumber(item?.subscribers?.length)}
										</span>{" "}
										abonnés
									</div>
									<div className="yeewuna-detail-auteur-statistic-text">
										<span className="me-1">
											{formatFollowerNumber(item?.nbre_abonne)}
										</span>
										<BsFillHeartFill style={{ fontSize: 20 }} />
									</div>
								</div>
								<div className="yeewuna-detail-auteur-statistic-autheur-btn-abonnement-container">
									<button
										className={`btn yeewuna-detail-auteur-statistic-autheur-btn-abonnement ${
											isFollow ? "btn__primary" : ""
										}`}
										onClick={handleToggleFollow}
									>
										{isFollow ? "Abonné" : "S’abonner"}
									</button>
									<Link
										to={`${
											location?.pathname?.startsWith("/auteur")
												? "/auteur"
												: "/utilisateur"
										}/messages`}
										className="btn yeewuna-detail-auteur-statistic-autheur-btn-abonnement no-link"
										state={item}
									>
										Message
									</Link>
								</div>
							</div>
							<div className="yeewuna-detail-autheur-titre-a-propos-container mb-5">
								<div className="yeewuna-detail-autheur-titre-a-propos mb-3">
									À propos de {getName(item)}
								</div>
								<div
									className="yeewuna-detail-autheur-a-propos"
									dangerouslySetInnerHTML={createMarkup(item?.bio)}
								/>
							</div>
							<div className="yeewuna-detail-autheur-ouvres-container">
								<div className="yeewuna-detail-autheur-titre-a-propos mb-4">
									Oeuvres de {getName(item)}
								</div>
								{item?.published_books?.length > 0 ? (
									<div className="ouvres-container">
										{item?.published_books.map((book) => (
											<LivreItem key={book.slug} item={book} />
										))}
									</div>
								) : (
									<AlertInfo message="Aucune oeuvre trouvée" />
								)}
							</div>
							<div className="yeewuna-detail-autheur-ouvres-container mt-4">
								<div className="yeewuna-detail-autheur-titre-a-propos mb-4">
									Fil d’actualité
								</div>
								<div>
									{articles && articles.length > 0 ? (
										articles?.map((item, i) => <ActuItem item={item} key={i} />)
									) : (
										<AlertInfo message="Aucune publication trouvée" />
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Toaster />
		</div>
	);
}

export default DetailsAuteur;
