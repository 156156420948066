import React from "react";
import { GiPriceTag } from "react-icons/gi";
import StarRatingComponent from "react-star-rating-component";
import { useBookFromLocation } from "../../../../utils/api/book/book.api";
import { createMarkup, getImage } from "../../../../utils/Utils";
const DetailLivre = () => {
  const [item] = useBookFromLocation();
  return (
    <div className="list-admin-admin">
      <div className="container-title-page-admin">
        <div className="content-title-admin">
          <h3 className="h3-title-admin">Livres</h3>
        </div>
      </div>
      <div className="container-admin-page-table admin-table mt-4">
        <h5 className="title-detail-livre-admin">Description du livre</h5>
        <div className="row pt-4">
          <div className="col-md-6 col-left-livre-detail-admin mb-3 d-flex">
            <div className="content-col-left-livre-detail-admin w-100">
              <img
                src={getImage(item?.couverture)}
                alt="Couverture du livre"
                className="img-couverture-livre"
              />
            </div>
          </div>
          <div className="col-md-6 col-left-livre-detail-admin mb-3 d-flex">
            <div className="content-col-left-livre-detail-admin w-100">
              <h3 className="h3-title-admin">{item?.title}</h3>
              <div className="content-head-detail-livre py-4">
                <div className="content-rate-livre">
                  <StarRatingComponent
                    name="rate2"
                    starCount={5}
                    value={4}
                    starColor="#F8BF5C"
                    emptyStarColor="#BDBDBD"
                  />
                </div>
                <div className="content-price-livre-detail-admin d-flex gap-2">
                  <p className="text-describe-detail-livre">{item?.price} FCFA</p>
                  <span style={{ color: "#F8BF5C" }}>
                    <GiPriceTag />
                  </span>
                </div>
              </div>
              <div className="content-text-describe-detail-livre">
                <p className="text-describe-detail-livre" dangerouslySetInnerHTML={createMarkup(item?.description)} />
              </div>
              <div className="b-b-1 py-3 mx-5"></div>
              <div className="info-auteur-detail-livre-item pb-3 mt-3">
                <p className="text-describe-detail-livre mb-0">Auteur:</p>
                <p className="color-theme-text-describe-livre mb-0">
                  {item?.author?.first_name + " " + item?.author?.last_name}
                </p>
              </div>
              <div className="info-auteur-detail-livre-item pb-3">
                <p className="color-theme-text-describe-livre mb-0">5.4k</p>
                <p className="text-describe-detail-livre mb-0">
                  utilisateurs ont ajouté ce livre à leur favoris
                </p>
              </div>
              <div className="info-auteur-detail-livre-item pb-3">
                <p className="color-theme-text-describe-livre mb-0">5.4k</p>
                <p className="text-describe-detail-livre mb-0">
                  utilisateurs ont acheté l’e-book
                </p>
              </div>
              <div className="info-auteur-detail-livre-item pb-3">
                <p className="color-theme-text-describe-livre mb-0">5.4k</p>
                <p className="text-describe-detail-livre mb-0">
                  utilisateurs ont acheté l’audio-book
                </p>
              </div>
              <div className="info-auteur-detail-livre-item pb-3">
                <p className="color-theme-text-describe-livre mb-0">5.4k</p>
                <p className="text-describe-detail-livre mb-0">
                  utilisateurs ont acheté le livre électronique
                </p>
              </div>
              <div className="info-auteur-detail-livre-item pb-3">
                <p className="color-theme-text-describe-livre mb-0">5.4k</p>
                <p className="text-describe-detail-livre mb-0">
                  recommandations
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailLivre;
