/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { ApiMoyenPaiement, ServicePayment, ServicePaymentCode } from "../../type";
import { Book } from "../book/book.type";
import { IOffer } from "../offer/offer.type";
import { OrderItem } from "../order/order.type";
import { Category } from "../category/category.type";

export enum UserType {
	admin = "admin",
	superAdmin = "superadmin",
	reader = "reader",
	author = "author",
}

export type CiviliteType = "homme" | "femme";

export interface IUser {
	id: number;
	slug: string | any;
	email: string;
	last_name: string;
	first_name: string;
	password: string;
	user_type: UserType;
	civilite: CiviliteType;
	phone: string;
	avatar: string | File | any;
	banniere: string | File | any;
	password_reset_count: number;
	first_connexion: boolean;
	created_at: string;
	last_login: string;
	bio: string;
	is_superuser: boolean;
	is_active: boolean;
	is_archived: boolean;
	is_staff: boolean;
	adress: string;
	books: Book[];
	published_books: Book[];
	subscribers: IFollow[];
	date_joined: Date | string;
	nbre_abonne: number;
	published_books_count: number;
	favorite_books_count: number;
	top_five_catogories: Category[];
}

export type UserFormData = Partial<
	Omit<IUser, "user_type" | "avatar"> & {
		user_type: string;
		avatar: File;
	}
>;

export interface AuthState {
	user: IUser | any;
	token?: string | null;
}

export type PaginationResults<T> = {
	count?: number;
	next?: string | null;
	previous?: string | null;
	results: T[];
};

export type TypeQuery = Partial<{
	page?: number;
	limit?: number;
	word?: string | null;
	slug?: string;
	name?: string | null;
	q?: string;
}>;

export type BecomeAuthorData = {
	step: number;
	book_name: string;
	book_price: string;
	book_category: number;
	description: string;
	book_pdf: File;
	book_image: File;
	epub: File;
	isbn: string;
	email: string;
	last_name: string;
	first_name: string;
	password: string;
	confirm_password: string;
	offer_id: number;
	user_id: number;
	phone: string;
	avatar: File;
	payment_method: typeof ServicePaymentCode;
};

export type BecomeAuthorResponse = {
	message: string;
	data: {
		book: Book;
		subscription: {
			id: number;
			slug: string;
			moyen_paiement: ApiMoyenPaiement;
			author: IUser;
		};
	};
};

export interface IBecomeTraderProps {
	navigation: any;
	register: UseFormRegister<BecomeAuthorData>;
	setValue?: UseFormSetValue<BecomeAuthorData>;
	errors: FieldErrors<BecomeAuthorData>;
	onSubmit: () => void;
	isLoading: boolean;
	handleSelectFiles: (e: React.ChangeEvent<HTMLInputElement>) => void;
	bookCover?: File;
	avatar?: File;
	description: string;
	handleChangeDescription: (e: string) => void;
	handleChoiceOffer: (item?: IOffer) => void;
	incrementProgress: (value: number) => void;
	offer: IOffer;
	onGetTransactionStatus: () => void;
	isFetching: boolean;
	isLoad: boolean;
	seconds: number;
	phone: string;
	handleChangePhone: (phone: string) => void;
}
export interface IFollow {
	id: number;
	slug: string;
	subscriber: IUser;
	subscribed_to: IUser;
}
export type FollowData = { subscriber: number; subscribed_to: number };

export interface DashboardAuthorGraphe {
	month: string;
	vente: number;
}
export interface DashboardAuthorData {
	total_clients: number;
	total_followers: number;
	total_livres: number;
	total_ventes: number;
	graphe: DashboardAuthorGraphe[];
}

export interface PaiementItem {
	user: IUser;
	count: number;
	order_total: number;
	payment_method: ServicePayment;
	pays: string;
	ville: string;
	Repere: string;
	adresse: string;
	numero_residence: string;
	name: string;
	phone: string;
	date: string;
	order_items: OrderItem[];
}
