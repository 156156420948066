import React from "react";
import { FaUsers } from "react-icons/fa";
import "./DashboardAdmin.css";
import LineChart from "./LineChat/LineChart";
import { ImBook } from "react-icons/im";
import { MdPayments } from "react-icons/md";
import { useGetAdminDashboardQuery } from "../../../../utils/api/admin/admin.api";
import { formatAmount, formatFollowerNumber } from "../../../../utils/Utils";
function DashboardAdmin() {
	const { data } = useGetAdminDashboardQuery();
	return (
		<div className="dashbord-admin-component">
			<div className="dash-admin-page-content-container mb-3">
				<div className="row mb-4">
					<div className="col-lg-3 col-md-6 mb-3 dis-flex">
						<div className="item-stat-dashboad-view">
							<div className="content-stat-dashboard-view">
								<div className="content-text-item-stat-dashboad-view">
									<div className="content-item-stat-dashboad-view mt-2">
										<p className="titre-item-stat-dashboad-view mb-1">
											Total utilisateurs
										</p>
									</div>
									<div className="icon-item-stat-dashboad-view">
										<p className="chiffre-item-stat-dashboad-view mb-1">
											{formatFollowerNumber(data?.total_users)}
											{/* <span
												style={{
													color: "#E59B34",
													fontSize: 14,
													fontWeight: 400,
												}}
											>
												+55%
											</span> */}
										</p>
									</div>
								</div>
								<div className="content-item-icon-stat-dashboard-view color-1">
									<FaUsers className="icon-item-stat-dashboad-view" />
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 mb-3 dis-flex">
						<div className="item-stat-dashboad-view">
							<div className="content-stat-dashboard-view">
								<div className="content-text-item-stat-dashboad-view">
									<div className="content-item-stat-dashboad-view mt-2">
										<p className="titre-item-stat-dashboad-view mb-1">
											Total auteurs
										</p>
									</div>
									<div className="icon-item-stat-dashboad-view">
										<p className="chiffre-item-stat-dashboad-view mb-1">
											{formatFollowerNumber(data?.total_auteurs)}
											{/* <span
												style={{
													color: "#E59B34",
													fontSize: 14,
													fontWeight: 400,
												}}
											>
												+55%
											</span> */}
										</p>
									</div>
								</div>
								<div className="content-item-icon-stat-dashboard-view color-2">
									<FaUsers className="icon-item-stat-dashboad-view" />
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 mb-3 dis-flex">
						<div className="item-stat-dashboad-view">
							<div className="content-stat-dashboard-view">
								<div className="content-text-item-stat-dashboad-view">
									<div className="content-item-stat-dashboad-view mt-2">
										<p className="titre-item-stat-dashboad-view mb-1">
											Total livres
										</p>
									</div>
									<div className="icon-item-stat-dashboad-view">
										<p className="chiffre-item-stat-dashboad-view mb-1">
											{formatFollowerNumber(data?.total_livres)}
											{/* <span
												style={{
													color: "#E59B34",
													fontSize: 14,
													fontWeight: 400,
												}}
											>
												+55%
											</span> */}
										</p>
									</div>
								</div>
								<div className="content-item-icon-stat-dashboard-view color-1">
									<ImBook className="icon-item-stat-dashboad-view" />
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 mb-3 dis-flex">
						<div className="item-stat-dashboad-view">
							<div className="content-stat-dashboard-view">
								<div className="content-text-item-stat-dashboad-view">
									<div className="content-item-stat-dashboad-view mt-2">
										<p className="titre-item-stat-dashboad-view mb-1">
											Revenus
										</p>
									</div>
									<div className="icon-item-stat-dashboad-view">
										<p className="chiffre-item-stat-dashboad-view mb-1">
											{formatAmount(data?.total_revenus)}
											{/* <span
												style={{
													color: "#E59B34",
													fontSize: 14,
													fontWeight: 400,
												}}
											>
												+20%
											</span> */}
										</p>
									</div>
								</div>
								<div className="content-item-icon-stat-dashboard-view color-3">
									<MdPayments className="icon-item-stat-dashboad-view" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="content-graph-admin">
					<LineChart graphData={data?.graphe} />
				</div>
			</div>
		</div>
	);
}

export default DashboardAdmin;
