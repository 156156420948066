import React from "react";
import { BsCameraFill } from "react-icons/bs";
import Modal from "react-modal";
import { IAdmin } from "../../../../../utils/api/admin/admin.type";
import { customStyles } from "../../../../../utils/theme";
import { IModalProps } from "../../../../../utils/type";
import { getAvatar } from "../../../../../utils/Utils";
import { BtnSubmit } from "../../../../common/Button";
import { FormError, InputAdmin } from "../../../../common/Input";
import CloseModalBtn from "../../../../shared/CloseModalBtn";
import useCrudAdmin from "../hooks/useCrudAdmin";

Modal.setAppElement("#root");
const AddAdminModal: React.FC<IModalProps<IAdmin>> = ({ isOpen, closeModal, item }) => {
	const { register, onSubmit, errors, isLoading, avatar, handleChangeAvatar } = useCrudAdmin(
		closeModal,
		item
	);
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={closeModal}
			style={customStyles}
			contentLabel="addAdminModal"
		>
			<div className="modal-header pe-2 py-1">
				<h5 className="modal-title-admin" id="ChangePasswordModalLabel">
					{item ? "Modifer l'" : "Ajouter un"} admin
				</h5>
				<CloseModalBtn onClick={closeModal} />
			</div>
			<form className="mt-4" onSubmit={onSubmit}>
				<div className="row row-add-student">
					<div className="col-md-12 mb-4">
						<div className="content-pp-profil-admin text-center pb-3">
							<div className="d-flex justify-content-center align-items-center pb-4 mb-4 admin-user-avatar-container">
								<label htmlFor="input-test" className="input-testt">
									<img
										src={avatar ? avatar : getAvatar(item?.avatar)}
										alt="profil utilisateur"
										className="pp-profil-user-admin"
									/>
									<div className="admin-user-avatar-edit-hover">
										<BsCameraFill className="edit-avatar-camera-icon" />
									</div>
								</label>

								<input
									type="file"
									className="input-add-avatar"
									id="input-test"
									onChange={handleChangeAvatar}
								/>
							</div>
							<FormError error={errors.avatar?.message} />
						</div>
					</div>
					<div className="col-md-6 col-form-add-student mb-3">
						<InputAdmin
							type="text"
							label="Nom"
							id="last_name"
							required
							placeholder="Nom"
							{...register("last_name")}
							error={errors?.last_name?.message}
						/>
					</div>
					<div className="col-md-6 col-form-add-student mb-3">
						<InputAdmin
							type="text"
							label="Prénom"
							id="first_name"
							required
							placeholder="Prénom"
							{...register("first_name")}
							error={errors?.first_name?.message}
						/>
					</div>

					<div className="col-md-6 col-form-add-student mb-3">
						<InputAdmin
							type="email"
							label="Adresse email"
							id="adresse_email"
							required
							placeholder="Email"
							{...register("email")}
							error={errors?.email?.message}
						/>
					</div>
					<div className="col-md-6 col-form-add-student mb-3">
						<InputAdmin
							type="text"
							label="Téléphone"
							id="phone"
							required
							placeholder="Téléphone"
							{...register("phone")}
							error={errors?.phone?.message}
						/>
					</div>
				</div>
				<div className="row row-content-btn-action pt-3">
					<div className="col-md-4 offset-md-8">
						<BtnSubmit
							label={item ? "Modifier" : "Ajouter"}
							isLoading={isLoading}
							full
						/>
					</div>
				</div>
			</form>
		</Modal>
	);
};

export default AddAdminModal;
