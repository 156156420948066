import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { RiFileTextLine, RiSettings3Fill } from "react-icons/ri";
import VersionChecker from "../../../../../versionChecker/VersionChecker";
import { CiHome, CiMail } from "react-icons/ci";
import { HiOutlineUsers } from "react-icons/hi";
import { GiWhiteBook } from "react-icons/gi";
import { CgProfile } from "react-icons/cg";
import { MdPayments } from "react-icons/md";
import { BiCategory, BiSupport } from "react-icons/bi";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { FiUser } from "react-icons/fi";
import { FaBloggerB, FaQuestion } from "react-icons/fa";
import { BsBodyText } from "react-icons/bs";
import Logo from "../../../../shared/FrontHeader/Logo";

function SidebarAdmin() {
	let location = useLocation();
	return (
		<div className="navbar-vertical-customisation-v2 no-view-mobile navbar-vertical-customisation-admin">
			<div className="hoverable mt-4" id="scrollbar">
				<div className="content">
					<div className="move-left-side-menu"></div>
					<div className="container-user-connect-info pb-4">
						<NavLink to={"/"} className="container-info-user-connect-navmenu">
							{/* <img
								src={logoHeader}
								className="yeewuna-logo-header"
								alt="logo de yeewuna"
							/> */}
							<Logo />
						</NavLink>
					</div>
					<ul className="nav nav-items-custom flex-column">
						{location?.pathname?.startsWith("/admin") && (
							<>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/admin/dashboard"
										data-tooltip-content="Accueil"
									>
										<span className="icon-container-nav-link-vertival">
											<CiHome />
										</span>
										<span className="hiddenable-navlink-label">Accueil</span>
									</NavLink>
								</li>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/admin/auteurs"
										data-tooltip-content="Auteurs"
									>
										<span className="icon-container-nav-link-vertival">
											<HiOutlineUsers />
										</span>
										<span className="hiddenable-navlink-label">Auteurs</span>
									</NavLink>
								</li>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/admin/utilisateurs"
										data-tooltip-content="Utilisateurs"
									>
										<span className="icon-container-nav-link-vertival">
											<HiOutlineUsers />
										</span>
										<span className="hiddenable-navlink-label">
											Utilisateurs
										</span>
									</NavLink>
								</li>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/admin/livres"
										data-tooltip-content="Livres"
									>
										<span className="icon-container-nav-link-vertival">
											<GiWhiteBook />
										</span>
										<span className="hiddenable-navlink-label">Livres</span>
									</NavLink>
								</li>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/admin/messages"
										data-tooltip-content="Messages"
									>
										<span className="icon-container-nav-link-vertival">
											<CiMail />
										</span>
										<span className="hiddenable-navlink-label">Messages</span>
									</NavLink>
								</li>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/admin/blog"
										data-tooltip-content="Livres"
									>
										<span className="icon-container-nav-link-vertival">
											<FaBloggerB />
										</span>
										<span className="hiddenable-navlink-label">Blog</span>
									</NavLink>
								</li>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/admin/categories"
										data-tooltip-content="Catégories"
									>
										<span className="icon-container-nav-link-vertival">
											<BiCategory />
										</span>
										<span className="hiddenable-navlink-label">Catégories</span>
									</NavLink>
								</li>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/admin/support"
										data-tooltip-content="Support"
									>
										<span className="icon-container-nav-link-vertival">
											<BiSupport fontSize={16} />
										</span>
										<span className="hiddenable-navlink-label">Support</span>
									</NavLink>
								</li>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/admin/faq"
										data-tooltip-content="Catégories"
									>
										<span className="icon-container-nav-link-vertival">
											<FaQuestion fontSize={16} />
										</span>
										<span className="hiddenable-navlink-label">Faq</span>
									</NavLink>
								</li>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/admin/devis"
										data-tooltip-content="devis"
									>
										<span className="icon-container-nav-link-vertival">
											<RiFileTextLine fontSize={16} />
										</span>
										<span className="hiddenable-navlink-label">Devis</span>
									</NavLink>
								</li>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/admin/commande-texte"
										data-tooltip-content="Commande de texte"
									>
										<span className="icon-container-nav-link-vertival">
											<BsBodyText fontSize={16} />
										</span>
										<span className="hiddenable-navlink-label">
											Commande texte
										</span>
									</NavLink>
								</li>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/admin/profil"
										data-tooltip-content="Mon profil"
									>
										<span className="icon-container-nav-link-vertival">
											<CgProfile />
										</span>
										<span className="hiddenable-navlink-label">Mon profil</span>
									</NavLink>
								</li>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/admin/parametres"
										data-tooltip-content="Paramètres"
									>
										<span className="icon-container-nav-link-vertival">
											<RiSettings3Fill />
										</span>
										<span className="hiddenable-navlink-label">Paramètres</span>
									</NavLink>
								</li>
							</>
						)}
						{location?.pathname?.startsWith("/auteur") && (
							<>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/auteur/accueil"
										data-tooltip-content="Accueil"
									>
										<span className="icon-container-nav-link-vertival">
											<CiHome />
										</span>
										<span className="hiddenable-navlink-label">Accueil</span>
									</NavLink>
								</li>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/auteur/livres"
										data-tooltip-content="Livres"
									>
										<span className="icon-container-nav-link-vertival">
											<GiWhiteBook />
										</span>
										<span className="hiddenable-navlink-label">Livres</span>
									</NavLink>
								</li>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/auteur/liste-des-auteurs"
										data-tooltip-content="Livres"
									>
										<span className="icon-container-nav-link-vertival">
											<FiUser />
										</span>
										<span className="hiddenable-navlink-label">Auteurs</span>
									</NavLink>
								</li>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/auteur/messages"
										data-tooltip-content="Messages"
									>
										<span className="icon-container-nav-link-vertival">
											<CiMail />
										</span>
										<span className="hiddenable-navlink-label">Messages</span>
									</NavLink>
								</li>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/auteur/articles"
										data-tooltip-content="articles"
									>
										<span className="icon-container-nav-link-vertival">
											<AiOutlineExclamationCircle />
										</span>
										<span className="hiddenable-navlink-label">
											Mon fil d’actualité
										</span>
									</NavLink>
								</li>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/auteur/paiements"
										data-tooltip-content="Paiements"
									>
										<span className="icon-container-nav-link-vertival">
											<MdPayments />
										</span>
										<span className="hiddenable-navlink-label">Paiements</span>
									</NavLink>
								</li>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
										to="/auteur/profil"
										data-tooltip-content="Mon profil"
									>
										<span className="icon-container-nav-link-vertival">
											<CgProfile />
										</span>
										<span className="hiddenable-navlink-label">Mon profil</span>
									</NavLink>
								</li>
							</>
						)}
					</ul>
					<div className="container-btn-logout">
						<div className="version-view-container ps-3">{"v" + VersionChecker()}</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SidebarAdmin;
