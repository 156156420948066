import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { cleannerError } from "../../../../../utils/Utils";
import { ApiBaseUrl, AppBaseUrl, IN_TECH_API_KEY } from "../../../../../utils/http";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { useCreateOrderMutation } from "../../../../../utils/api/order/order.api";
import { useAppSelector } from "../../../../../redux/hooks";
import { OrderFormData } from "../../../../../utils/api/order/order.type";
// import { useNavigate } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useBuyCart(navigation: any) {
	const validationSchema = yup.object().shape({
		step: yup.number().default(1),
		name: yup.string().when("step", {
			is: 1,
			then: yup.string().required().label("Prénom et nom"),
		}),
		telephone: yup.string().when("step", {
			is: 1,
			then: yup.string().required().label("Téléphone"),
		}),
		country: yup.string().when("step", {
			is: 1,
			then: yup.string().required().label("Pays"),
		}),
		appartment: yup.string().when("step", {
			is: 1,
			then: yup.string().required().label("Appartement"),
		}),
		adresse: yup.string().when("step", {
			is: 1,
			then: yup.string().required().label("Adresse"),
		}),
		payment_method: yup.string().when("step", {
			is: 2,
			then: yup
				.string()
				.required()
				.label("Mode de paiement")
				.typeError("Veuillez choisir un mode de paiement"),
		}),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<OrderFormData>({
		resolver: yupResolver(validationSchema),
	});
	const [createOrder, { isLoading: isCreate }] = useCreateOrderMutation();
	const [isLoading, setIsLoading] = useState<boolean>(isCreate);
	const [isLoad, setIsLoad] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const [seconds, setSeconds] = useState<number>(20);
	const [phone, setPhone] = useState<string>("");
	const [adress, setAdress] = useState<string>("");
	const [externalTransactionId, setExternalTransactionId] = useState<number | string>();
	const cart = useAppSelector((state) => state.panier);
	const { user } = useAppSelector((s) => s?.user);

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (isFetching) {
			const timer = seconds > 0 ? setInterval(() => setSeconds(seconds - 1), 1000) : 0;
			return () => clearInterval(timer);
		}
	}, [seconds, isFetching]);

	const handleChangePhone = (phone: string) => {
		setValue("telephone", phone.slice(3));
		setPhone(phone);
	};
	const handleChangeAdress = (value) => {
		// console.log(value);
		setValue("adresse", value?.rue);
		setAdress(value?.rue);
	};
	const onGetTransactionStatus = () => {
		setIsLoad(true);
		let raw = {
			externalTransactionId: externalTransactionId,
		};
		const requestOptions: RequestInit = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Secretkey: IN_TECH_API_KEY,
			},
			body: JSON.stringify(raw),
		};

		fetch("https://api.intech.sn/api-services/get-transaction-status", requestOptions)
			.then((response) => response.json())
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			.then((result: any) => {
				setIsLoad(false);
				// console.log(result);
				if (result?.data?.status === "SUCCESS") {
					setIsFetching(false);
					window.location.href = `${AppBaseUrl}/connexion?status=success`;
					// console.log("done");
				} else if (result?.data?.status === "PENDING") {
					setIsFetching(true);
					Swal.fire({
						icon: "info",
						title: `Votre paiement est en attente!`,
						iconColor: Color.success,
						showConfirmButton: false,
						timer: 3000,
					});
				} else if (result?.data?.status === "REFUNDED") {
					setIsFetching(false);
					Swal.fire({
						icon: "info",
						title: `Votre paiement a été remboursé!`,
						iconColor: Color.success,
						showConfirmButton: false,
						timer: 3000,
					});
					// .then(() => closeModal());
				} else if (result?.data?.status === "PROCESSING") {
					setIsFetching(true);
					Swal.fire({
						icon: "info",
						title: `Votre paiement est en cours de traitement!`,
						iconColor: Color.success,
						showConfirmButton: false,
						timer: 3000,
					});
				} else {
					console.log("no done");
					setIsFetching(false);
					Swal.fire({
						icon: "error",
						title: result?.data?.errorType?.message
							? result?.data?.errorType?.message
							: `${result?.msg}`,
						showConfirmButton: false,
						timer: 3000,
					});
					// .then(() => closeModal());
				}
			})
			.catch((error) => {
				setIsFetching(false);
				setIsLoad(false);
				console.log("error", error);
				Swal.fire({
					icon: "error",
					title: error?.data?.message
						? error?.data?.message
						: `Erreur de statut ${error?.status} est survenue`,
					showConfirmButton: false,
					timer: 5000,
				});
			});
	};

	const onSubmit = async (data: OrderFormData) => {
		console.log("data", data);
		switch (data?.step) {
			case 1:
				navigation.next();
				break;
			default:
				const dataToSend: OrderFormData = {
					...data,
					order_items: cart?.cartItems?.map((item) => {
						return {
							item: item?.item?.id,
							item_type: item?.item_type,
							price: item?.price,
							quantity: item?.qty,
						};
					}),
					user: user?.id,
					count: cart.totalCount,
					order_total: cart.subAmount,
				};
				console.log({ dataToSend });

				const result = await createOrder(dataToSend);
				if ("data" in result) {
					console.log(result?.data);
					setIsLoading(true);

					try {
						console.log(result?.data);
						let dataToSend = {
							amount: cart.subAmount,
							codeService: data?.payment_method,
							externalTransactionId: result?.data?.slug,
							callbackUrl: `${ApiBaseUrl}/api/callback/`,
							apiKey: IN_TECH_API_KEY,
							phone: data?.telephone,
							operationDescription: "Achat de livres sur la plateforme de yeewuna",
							sender: "Yeewuna",
							successRedirectUrl: `${AppBaseUrl}/utilisateur/bibliotheque?status=success`,
							errorRedirectUrl: `${AppBaseUrl}/utilisateur/bibliotheque?status=error`,
							...({ ...result?.data, env: "test" } ?? {}),
						};
						const requestOptions: RequestInit = {
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								Accept: "application/json",
							},
							body: JSON.stringify(dataToSend),
						};
						const res = await fetch(
							"https://api.intech.sn/api-services/operation",
							requestOptions
						).then((res) => {
							if (res.ok) {
								// setIsLoading(false);
								return res.json();
							}
							throw {
								data: { message: `Une erreur est survenue code: ${res.status}` },
							};
						});

						if (res?.error) {
							setIsLoading(false);
							// console.log("error");
							let message = "";
							if (res.data) {
								for (let [k, v] of Object.entries(res.data)) {
									if (Array.isArray(v)) {
										message = `${message}\n${k}: ${v.join(", ")}`;
									}
								}
							}
							if (message) {
								Swal.fire({
									icon: "error",
									title: message,
									showConfirmButton: false,
									timer: 5000,
								});
							} else if (res.msg) {
								Swal.fire({
									icon: "error",
									title: res.msg,
									showConfirmButton: false,
									timer: 5000,
								});
							} else {
								Swal.fire({
									icon: "error",
									title: res?.msg
										? res?.msg
										: `Erreur de statut code ${res?.code} est survenue!`,
									showConfirmButton: false,
									timer: 5000,
								});
							}
							return false;
						}
						if (!res?.error) {
							setIsLoading(false);
							console.log({ res });
							const { deepLinkUrl } = res.data;
							const msg =
								"L'opération s'est effectuée avec succès, Vous allez recevoir un message de confirmation pour finaliser le paiement";
							if (deepLinkUrl) {
								Swal.fire({
									icon: "success",
									iconColor: Color.success,
									title: msg,
									showConfirmButton: false,
									timer: 4000,
								}).then(() => {
									window.location.href = deepLinkUrl;
								});
							} else {
								Swal.fire({
									icon: "success",
									title: msg,
									showConfirmButton: true,
									timer: 10000,
									confirmButtonColor: Color.success,
								}).then(() => {
									setExternalTransactionId(res?.data?.externalTransactionId);
									navigation.go("check-paiement-status");
								});
							}

							return true;
						}
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
					} catch (error: any) {
						const err = error?.data;
						console.log("error", err);
						return false;
					}
				}
				if ("error" in result) {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					const err = result.error as any;
					// console.log(err?.data?.email);
					if (err?.data?.email[0]?.includes("user with this email already exists")) {
						Swal.fire({
							icon: "error",
							title: "Cet email existe déjà,veuillez choisir un autre email.",
							showConfirmButton: false,
							timer: 5000,
						});
					} else {
						Swal.fire({
							icon: "error",
							title: err?.data?.message
								? err?.data?.message
								: `Erreur de statut ${err?.status}`,
							showConfirmButton: false,
							timer: 5000,
						});
					}
				}
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		phone,
		handleChangePhone,
		user,
		isLoad,
		onGetTransactionStatus,
		isFetching,
		seconds,
		adress,
		handleChangeAdress,
	};
}

export default useBuyCart;
