import * as React from "react";
import "./HomePage.css";
import FrontHeader from "../shared/FrontHeader/FrontHeader";
import FrontFooter from "../shared/FrontFooter/FrontFooter";
import BannerPage from "../shared/BannerPage";
import ImgAvantage from "../../assets/appImages/avantage.png";
import IconAvantage from "../../assets/icons/avantage.png";
import IconAudio from "../../assets/icons/audio.png";
import IconBuy from "../../assets/icons/buy.png";
import ImgOuvrage from "../../assets/appImages/rafiki.png";
import ListOffer from "./offer/ListOffer";
import BestBooks from "./BestBooks";
import ReputedAuthors from "./ReputedAuthors";
import NewsLetterForm from "./NewsLetterForm";
import { Toaster } from "react-hot-toast";

const HomePage = () => {
	return (
		<div className="landing-page-component">
			<FrontHeader />
			<div className="page-content">
				<section className="section-banner-homepage">
					<BannerPage />
				</section>
				<section className="section-avantage-home-page pt-3 pt-md-5">
					<div className="container-app-yeewuna">
						<div className="content-title-section-avantage text-center">
							<h2 className="h2-theme position-relative">De nombreux avantages !</h2>
						</div>
						<div className="row avantage-yeewuna-container py-3 py-md-5">
							<div className="col-md-6 col-lg-4">
								<div className="avantage-home-page-item mb-4">
									<div className="container-icon-avantage">
										<img
											src={IconAvantage}
											alt="icon avantage"
											className="icon-avantage-list"
										/>
									</div>
									<div className="content-text-avantage">
										<p className="title-avantage-list-homepage mb-1">
											Le livre en ligne{" "}
										</p>
										<p className="text-avantage-list-homepage mb-0">
											Le livre en ligne, une innovation qui révolutionne
											l'accès à la lecture. Il permet aux lecteurs du monde
											entier d'avoir accès à des milliers de contenus livres.
											Avec le livre en ligne, la lecture devient plus
											accessible, plus pratique et plus économique pour chacun
											d’entre nous !
										</p>
									</div>
								</div>
								<div className="avantage-home-page-item mb-4">
									<div className="container-icon-avantage">
										<img
											src={IconAudio}
											alt="icon avantage"
											className="icon-avantage-list"
										/>
									</div>
									<div className="content-text-avantage">
										<p className="title-avantage-list-homepage mb-1">
											Découvrez le livre audio
										</p>
										<p className="text-avantage-list-homepage mb-0">
											Plongez dans une nouvelle expérience de lecture,
											pratique et immersive avec le livre audio qui vous offre
											un accès illimité à des milliers de titres, où que vous
											soyez. C’est le moment de libérer vos mains et vos yeux
											et vous laissez transporter par la voix captivante de
											narrateurs professionnels.
										</p>
									</div>
								</div>
							</div>
							<div className="col-md-6 col-lg-4 no-view-mobile">
								<div className="content-img-avantage">
									<img
										src={ImgAvantage}
										alt="photos avantage"
										className="img-avantage-home"
									/>
								</div>
							</div>
							<div className="col-md-6 col-lg-4">
								<div className="avantage-home-page-item mb-4">
									<div className="container-icon-avantage">
										<img
											src={IconBuy}
											alt="icon avantage"
											className="icon-avantage-list"
										/>
									</div>
									<div className="content-text-avantage">
										<p className="title-avantage-list-homepage mb-1">
											Acheter en un clic
										</p>
										<p className="text-avantage-list-homepage mb-0">
											Explorez votre nouvel univers de lecture sans quitter
											votre canapé. Plus besoin de chercher des heures en
											librairie, un simple clic suffit pour accéder à un monde
											de personnages et d'aventures. Alors laissez-vous tenter
											par cette expérience simple et rapide, et entrez en
											quelques instants dans l'univers captivant de vos livres
											préférés.
										</p>
									</div>
								</div>
								<div className="avantage-home-page-item mb-4">
									<div className="container-icon-avantage">
										<img
											src={IconAvantage}
											alt="icon avantage"
											className="icon-avantage-list"
										/>
									</div>
									<div className="content-text-avantage">
										<p className="title-avantage-list-homepage mb-1">
											Des histoires pour tous
										</p>
										<p className="text-avantage-list-homepage mb-0">
											Notre plateforme de lecture en ligne propose des
											histoires captivantes pour tous les goûts. Plongez dans
											des aventures fascinantes à travers notre large
											sélection d'e-books et audiobook. Rejoignez notre
											communauté de lecteurs passionnés et découvrez de
											nouveaux titres chaque semaine.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section-avantage-home-page container-abonnement-home-page ">
					<div className="container-app-yeewuna py-5">
						<div className="content-title-section-avantage text-center mb-4">
							<h2 className="h2-theme position-relative">Nos offres d’abonnement</h2>
							<p className="text-avantage-list-homepage pt-4">
								Choisissez le forfait qui vous convient
							</p>
						</div>
						<ListOffer />
					</div>
				</section>
				{/*** Meilleurs ouvrages ****/}
				<BestBooks />

				<div className="section-avantage-home-page">
					<div className="container-app-yeewuna">
						<div className="row row-ouvrage d-flex align-items-center">
							<div className="col-md-6 col-ouvrage mb-3">
								<div className="col-ouvrage">
									<h2 className="title-ouvrage-partout">
										Vos ouvrages avec vous, partout!
									</h2>
									<div className="content-text-ouvrage-partout pt-3">
										<p className="text-ouvrage-partout">
											Parcourez notre vaste bibliothèque et emportez vos
											livres préférés partout avec vous grâce à notre
											plateforme de lecture en ligne. Notre plateforme
											intuitive et conviviale offre un accès instantané à des
											milliers d'ouvrages en ebook et audiobook, du classique
											à la fiction contemporaine, en passant par les livres de
											développement personnel et les guides pratiques.
										</p>
										<p className="text-ouvrage-partout">
											Plus besoin de transporter de lourds livres ou de faire
											la queue dans une librairie, notre plateforme vous
											permet de lire à votre rythme, sur votre ordinateur,
											tablette ou téléphone portable. Profitez d'une
											expérience de lecture immersive, avec des
											fonctionnalités supplémentaires telles que des signets,
											des surlignages et des notes pour vous aider à mieux
											comprendre et assimiler le contenu. Alors,
											qu'attendez-vous pour rejoindre notre communauté de
											lecteurs passionnés ?
										</p>
									</div>
								</div>
							</div>
							<div className="col-md-6 col-ouvrage mb-3">
								<div className="content-col-right-ouvrage">
									<img
										src={ImgOuvrage}
										alt="ouvrage"
										className="img-ouvrage-partout"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<ReputedAuthors />
				<section className="container-newsletter">
					<div className="container-app-yeewuna">
						<div className="row row-newsletter">
							<div className="col-lg-6 col-md-12 col-left-newsletter mb-3">
								<div className="col-left-newsletter">
									<h2 className="title-newsletter">
										S’inscrire à notre newsletter
									</h2>
									<p className="text-newsletter mb-0">
										lorem ipsum dolor sit amet
									</p>
								</div>
							</div>
							<div className="col-lg-6 col-md-12 content-col-right-newsletter mb-3">
								<NewsLetterForm />
							</div>
						</div>
					</div>
				</section>
			</div>
			<Toaster />
			<FrontFooter />
		</div>
	);
};

export default HomePage;
