import React from "react";
import "./Panier.css";
import book from "../../../../assets/appImages/Books_panier.png";
import { NavLink } from "react-router-dom";
import {
	CartItemType,
	decrement,
	getCartCount,
	getSubTotal,
	increment,
	removeCartItem,
} from "../../../../redux/slice/Cart.slice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { formatMontant, getImage } from "../../../../utils/Utils";
import { AiFillDelete } from "react-icons/ai";
import { Color } from "../../../../utils/theme";
function Counter({
	count,
	setCount,
}: {
	count: number;
	setCount: React.Dispatch<React.SetStateAction<number>>;
}) {
	// const [count, setCount] = React.useState(0);

	const decrement = () => {
		setCount((prevState) => prevState - 1);
	};

	const increment = () => {
		setCount((prevState) => prevState + 1);
	};

	return (
		<div className="btn-wrap">
			<button
				className="decrement-btn btn-counter-item"
				disabled={count <= 1}
				onClick={decrement}
			>
				−
			</button>
			<div className="num">{count}</div>
			<button className="increment-btn btn-counter-item" onClick={increment}>
				+
			</button>
		</div>
	);
}

const CartItem = ({ item }: { item: CartItemType }) => {
	const { user } = useAppSelector((s) => s?.user);
	const [count, setCount] = React.useState(item?.qty);
	const dispatch = useAppDispatch();

	const getCartItems = () => {
		dispatch(getSubTotal(user?.id));
		dispatch(getCartCount(user?.id));
	};
	React.useEffect(() => {
		if (count > item?.qty) {
			console.log("inc", count, item?.qty);
			dispatch(increment(item));
			getCartItems();
		}

		if (count < item?.qty) {
			console.log("dec", count, item?.qty);
			dispatch(decrement(item));
			getCartItems();
		}
	}, [count]);

	const onRemoveItem = () => {
		dispatch(removeCartItem(item));
		getCartItems();
	};
	return (
		<div className="row align-items-center b-b-1 pb-5 pt-3" key={item?.item?.id}>
			<div className="col-lg-7 col-md-12 col-element-panier-user-item mb-3">
				<div className="content-col-element-panier-user-item">
					<div className="content-img-col-element-panier-user-item">
						<img
							src={getImage(item?.item?.couverture)}
							alt="Couverture livre"
							className="img-col-element-panier-user-item"
						/>
					</div>
					<div className="content-info-livre-col-element-panier-user-item">
						<p className="title-col-element-panier-user-item">{item?.item?.title}</p>
						<p className="describe-livre-panier-user-item">Type: {item?.item_type}</p>
						<p className="title-col-element-panier-user-item mb-0">
							{formatMontant(item?.price)}
						</p>
					</div>
				</div>
			</div>
			<div className="col-lg-5 col-md-12 mb-3">
				<div className="content-col-right-panier-user-item d-flex justify-content-between">
					<div className="content-input-increment-panier">
						<div className="counter">
							<Counter setCount={setCount} count={count} />
						</div>
						<div className="content-price-book-panier">
							<p
								className="title-col-element-panier-user-item mb-0"
								style={{ color: "#283262" }}
							>
								{formatMontant(item?.price * item?.qty)}
							</p>
						</div>
					</div>
					<div>
						<span onClick={onRemoveItem} role="button">
							<AiFillDelete color={Color.red} fontSize={24} />
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

function Panier() {
	const { cartItems, subAmount } = useAppSelector((state) => state.panier);

	return (
		<div className="container-panier-component">
			<div className="row row-panier-user">
				<div
					className={`${
						!!cartItems?.length ? "col-lg-8" : "col-lg-12"
					} col-md-12  col-panier-user mb-3`}
				>
					<div className="user-dash-page-content-container mb-3">
						<div className="yeewuna-page-bibliotheque-titre mb-4">Panier</div>
						{/* <div className="container-header-panier-user tabs-container admin-table mb-4">
              <button
                className="tab-item tab-active-item btn"
                style={{ borderRadius: 0 }}
              >
                E-book
              </button>
              <button className="tab-item btn" style={{ borderRadius: 0 }}>
                Livre
              </button>
              <button className="tab-item btn" style={{ borderRadius: 0 }}>
                Audiobook
              </button>
            </div> */}
						<div className="content-panier-element b-t-1  py-5">
							<div className="content-element-panier-item">
								{!!cartItems?.length &&
									cartItems.map((item) => (
										<CartItem item={item} key={item?.item?.id} />
									))}
								{!cartItems?.length && (
									<div className="content-panier-vide text-center">
										<div>
											<div className="content-img-panier-vide pb-4 text-center">
												<img
													src={book}
													alt="Panier"
													className="img-panier-vide w-30"
												/>
											</div>
											<h3 className="titre-panier-vide">
												Votre panier est vide!
											</h3>
											<p className="text-panier-vide">
												Veuillez ajouter des éléments pour pouvoir acheter.
											</p>
											<div className="content-btn-action-panier pt-4 text-center">
												<NavLink
													to="/utilisateur/accueil"
													className="btn btn-page-title-admin "
												>
													Acheter
												</NavLink>
											</div>
										</div>
									</div>
								)}
								{/* <div className="row align-items-center b-b-1 py-5">
                  <div className="col-lg-7 col-md-12 col-element-panier-user-item mb-3">
                    <div className="content-col-element-panier-user-item">
                      <div className="content-img-col-element-panier-user-item">
                        <img
                          src={Photo}
                          alt="Couverture livre"
                          className="img-col-element-panier-user-item"
                        />
                      </div>
                      <div className="content-info-livre-col-element-panier-user-item">
                        <p className="title-col-element-panier-user-item">
                          Inassouvies, nos vies
                        </p>
                        <p className="describe-livre-panier-user-item">
                          Livre audio
                        </p>
                        <p className="title-col-element-panier-user-item mb-0 pb-3">
                          12.000 FCFA
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-12 mb-3">
                    <div className="content-col-right-panier-user-item">
                      <div className="content-input-increment-panier">
                        <div className="counter">
                          <Counter decrementBy={1} incrementBy={1} />
                        </div>
                        <div className="content-price-book-panier">
                          <p
                            className="title-col-element-panier-user-item mb-0 pb-3"
                            style={{ color: "#283262" }}
                          >
                            12.000 FCFA
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
							</div>
						</div>
					</div>
				</div>
				{!!cartItems?.length && (
					<div className="col-md-12 col-lg-4 col-panier-user mb-3">
						<div className="user-dash-page-content-container mb-3">
							<div className="yeewuna-page-bibliotheque-titre mb-4">Détails</div>
							<div className="content-info-detail-panier-user b-t-1 py-4">
								<div className="info-detail-panier-user-item pb-3">
									<p className="text-libelle-detail-panier mb-0">Sous-total:</p>
									<p className="text-value-detail-panier-user mb-0">
										{formatMontant(subAmount)}
									</p>
								</div>
								{/* <div className="info-detail-panier-user-item">
									<p className="text-libelle-detail-panier mb-0">Remise:</p>
									<p className="text-value-detail-panier-user mb-0"></p>
								</div> */}
							</div>
							<div className="content-info-detail-panier-user b-t-1 py-4">
								<div className="info-detail-panier-user-item pb-3">
									<p className="text-libelle-detail-panier mb-0">Total:</p>
									<p className="text-value-detail-panier-user mb-0">
										{formatMontant(subAmount)}
									</p>
								</div>
							</div>
							<div className="content-btn-validate-panier my-3 mx-4 text-center">
								<NavLink
									to="/utilisateur/remplir-information-complementaire"
									className="btn btn-page-title-admin"
								>
									Acheter
								</NavLink>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default Panier;
