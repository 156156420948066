import React from "react";
import "./HeaderAdmin.css";
import { Link, NavLink, useLocation } from "react-router-dom";
import { RiNotification3Line } from "react-icons/ri";
import SidebarMobile from "../SidebarAdmin/SidebarMobile";
import "../SidebarAdmin/SidebarAdmin.css";
import SidebarUserMobile from "../../../Utilisateur/NavbarUser/SidebarUser/SidebarUserMobile";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { onlogout } from "../../../../../redux/slice/User.slice";
import { getAvatar, isAutheur, isVisiteur } from "../../../../../utils/Utils";
import { useNotification } from "../../../../../utils/api/notification/notification.api";
import moment from "moment";
import logoHeader from "../../../../../assets/appImages/logoHeader.png";

const HeaderAdmin: React.FC = () => {
	const [showNotifications, setShowNotifications] = React.useState(false);
	let location = useLocation();
	let onShowAndHideNotification = () => {
		if (showNotifications === false) {
			setShowNotifications(true);
		} else {
			setShowNotifications(false);
		}
	};

	const { count, data, readNotif, readAll } = useNotification();

	const { user } = useAppSelector((s) => s?.user);
	const getUserLink = () => {
		if (location?.pathname?.startsWith("/admin")) {
			return "/admin";
		} else if (location?.pathname?.startsWith("/auteur")) {
			return "/auteur";
		} else {
			return "/utilisateur";
		}
	};
	const dispatch = useAppDispatch();
	const handleLogout = () => {
		dispatch(onlogout());
		window.location.pathname = "/connexion";
	};

	return (
		<div className="admin-header-component">
			<nav className="empty-fluidy alling-header navbar navbar-expand-md navbar-dark fixed-top">
				<div className="headering-admin">
					<div className="content-logo-header-admin">
						<img
							src={logoHeader}
							className="yeewuna-logo-header"
							alt="logo de yeewuna"
						/>
					</div>
					<div className="header-others-items">
						<div className="search-form-container"></div>
						<div className="container-info-user-connect-header">
							<div className="container-menu-header-admin gap-3">
								<div
									className="notification-item-container linkable position-relative"
									onClick={() => onShowAndHideNotification()}
								>
									<div className="notification-item-content">
										<RiNotification3Line className="notif-icon" />
										{count > 0 && (
											<span className="notification-counter">{count}</span>
										)}
									</div>
								</div>
							</div>
							<div className="connected-user-container">
								<div className="connected-user-content ml-auto">
									<div className="dropdown dis-flex">
										<button
											className="user-acount-dashboard-header dropdown-toggle btn"
											type="button"
											id="dropdownMenuButton"
											data-bs-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
										>
											<div className="user-acount-dashboard-header-display g-2">
												<img
													src={getAvatar(user?.avatar)}
													className="user-avatar"
													alt="User Avatar"
												/>
												<span className="name-user-connect-header ps-2">
													{`${user?.first_name} ${user?.last_name}`}
												</span>
											</div>
										</button>
										<div
											className="dropdown-menu dropdown-menu-custom"
											aria-labelledby="dropdownMenuButton"
										>
											<NavLink
												to={
													isVisiteur(user)
														? "/utilisateur/mon-profil"
														: isAutheur(user)
														? "/auteur/profil"
														: "/admin/profil"
												}
												className="dropdown-item"
												style={{ fontSize: 16 }}
											>
												{`${user?.first_name} ${user?.last_name}`}
											</NavLink>
											<div className="dropdown-divider"></div>
											<button
												type="button"
												className="dropdown-item"
												style={{ fontSize: 16 }}
												onClick={handleLogout}
											>
												Deconnexion
											</button>
										</div>
									</div>
								</div>
							</div>
							<div className="hiddenable-nav-mobile-container no-view-desktop">
								<div className="hiddenable-nav-mobile-content">
									<div className="container-nav-mobile">
										{location?.pathname?.startsWith("/admin") && (
											<>
												<SidebarMobile />
											</>
										)}
										{location?.pathname?.startsWith("/auteur") && (
											<>
												<SidebarMobile />
											</>
										)}
										{location?.pathname?.startsWith("/utilisateur") && (
											<>
												<SidebarUserMobile />
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{showNotifications && (
					<div className={"header-hiddenable-notification-section"}>
						{data && data?.length > 0 ? (
							<ul className="header-hiddenable-notification-ul mb-0 py-2">
								{data?.slice(0, 4)?.map((item) => (
									<li
										className="header-hiddenable-notification-li"
										key={item?.slug}
									>
										<NavLink
											to={
												item?.type === "message"
													? `${getUserLink()}/messages`
													: `${getUserLink()}/notifications`
											}
											onClick={() => readNotif(item?.slug)}
											className="notification-item-link d-flex b-0"
										>
											<div className="content-text-notif ps-2">
												<p className="text-notif mb-1">{item?.message}</p>
												<p className="text-date-notif mb-0">
													{moment(item?.created_at).format(
														"DD/MM/YYYY-HH:MM"
													)}
												</p>
											</div>
										</NavLink>
									</li>
								))}
								<li className="header-hiddenable-notification-li">
									<Link
										to={`${getUserLink()}/notifications`}
										className="notification-item-link d-flex no-link"
										onClick={readAll}
									>
										<div className="content-text-notif ps-2">
											<p className="text-notif m-0">
												Voir toutes les notifications
											</p>
										</div>
									</Link>
								</li>
							</ul>
						) : (
							<li className="header-hiddenable-notification-li">
								Il n'y a aucune notification .
							</li>
						)}
					</div>
				)}
			</nav>
		</div>
	);
};

export default HeaderAdmin;
