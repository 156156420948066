import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { AdminApi } from "../utils/api/admin/admin.api";
import { ArticleApi } from "../utils/api/article/article.api";
import { AuthApi } from "../utils/api/auth/auth.api";
import { BookApi } from "../utils/api/book/book.api";
import { CategoryApi } from "../utils/api/category/category.api";
import { ChatApi } from "../utils/api/chat/chat.api";
import { ForumApi } from "../utils/api/forum/forum.api";
import { OfferApi } from "../utils/api/offer/offer.api";
import { UserApi } from "../utils/api/user/user.api";
import { currentEnv, Env } from "../utils/http";
import { UserSlice } from "./slice/User.slice";
import storage from "redux-persist/lib/storage";
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import { useCartSlice } from "./slice/Cart.slice";
import { ContactApi } from "../utils/api/contact/contact.api";
import { NewsletterApi } from "../utils/api/newsletter/newsletter.api";
import { FaqApi } from "../utils/api/faq/faq.api";
import { TeamApi } from "../utils/api/team/team.api";
import { NotificationApi } from "../utils/api/notification/notification.api";
import { ConditionApi } from "../utils/api/condition/condition.api";
import { OrderApi } from "../utils/api/order/order.api";
import { CommandeTextApi } from "../utils/api/commandeText/commandeText.api";
import { ConfigApi } from "../utils/api/config/config.api";

const persistConfig = {
	key: "root",
	storage: storage,
	blacklist: [
		`${UserSlice.name}`,
		`${UserApi.reducerPath}`,
		`${AuthApi.reducerPath}`,
		`${UserApi.reducerPath}`,
		`${CategoryApi.reducerPath}`,
		`${BookApi.reducerPath}`,
		`${OfferApi.reducerPath}`,
		`${ArticleApi.reducerPath}`,
		`${AdminApi.reducerPath}`,
		`${ChatApi.reducerPath}`,
		`${ForumApi.reducerPath}`,
		`${TeamApi.reducerPath}`,
		`${NewsletterApi.reducerPath}`,
		`${ContactApi.reducerPath}`,
		`${FaqApi.reducerPath}`,
		`${FaqApi.reducerPath}`,
		`${NotificationApi.reducerPath}`,
		`${OrderApi.reducerPath}`,
		`${CommandeTextApi.reducerPath}`,
		`${ConfigApi.reducerPath}`,
	],
};

export const rootReducers = combineReducers({
	[useCartSlice.name]: useCartSlice.reducer,
	[AuthApi.reducerPath]: AuthApi.reducer,
	[UserSlice.name]: UserSlice.reducer,
	[UserApi.reducerPath]: UserApi.reducer,
	[CategoryApi.reducerPath]: CategoryApi.reducer,
	[BookApi.reducerPath]: BookApi.reducer,
	[OfferApi.reducerPath]: OfferApi.reducer,
	[ArticleApi.reducerPath]: ArticleApi.reducer,
	[AdminApi.reducerPath]: AdminApi.reducer,
	[ChatApi.reducerPath]: ChatApi.reducer,
	[ForumApi.reducerPath]: ForumApi.reducer,
	[ContactApi.reducerPath]: ContactApi.reducer,
	[NewsletterApi.reducerPath]: NewsletterApi.reducer,
	[FaqApi.reducerPath]: FaqApi.reducer,
	[TeamApi.reducerPath]: TeamApi.reducer,
	[NotificationApi.reducerPath]: NotificationApi.reducer,
	[ConditionApi.reducerPath]: ConditionApi.reducer,
	[OrderApi.reducerPath]: OrderApi.reducer,
	[CommandeTextApi.reducerPath]: CommandeTextApi.reducer,
	[ConfigApi.reducerPath]: ConfigApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducers);

const store = configureStore({
	reducer: persistedReducer,
	devTools: Env === currentEnv,
	middleware: (getDefaultMiddleware) => [
		...getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
		AuthApi.middleware,
		UserApi.middleware,
		CategoryApi.middleware,
		BookApi.middleware,
		OfferApi.middleware,
		ArticleApi.middleware,
		AdminApi.middleware,
		ChatApi.middleware,
		ForumApi.middleware,
		ContactApi.middleware,
		NewsletterApi.middleware,
		FaqApi.middleware,
		TeamApi.middleware,
		NotificationApi.middleware,
		ConditionApi.middleware,
		OrderApi.middleware,
		CommandeTextApi.middleware,
		ConfigApi.middleware,
	],
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export default store;
