/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import { ReactReader, ReactReaderStyle } from "react-reader";
import { ApiBaseUrl } from "../../../../utils/http";
import { AppLocalStorage } from "../../../../utils/storage";
import { CircleSpinner } from "../../../common/Button";
import BuyBookModal from "./BuyBookModal";
import { useToggleModal } from "../../../../utils/hooks";
import { useBookFromLocation } from "../../../../utils/api/book/book.api";
import { useNavigate } from "react-router-dom";

function LectureLivre() {
	// And your own state logic to persist state
	const [page, setPage] = useState("");
	console.log({ page });
	const renditionRef = useRef<any>(null);
	const tocRef = useRef<any>(null);
	const [location, setLocation] = useState("");
	const { closeModal, isShowModal, openModal } = useToggleModal();
	const [firstRenderDone, setFirstRenderDone] = useState(false);
	const [item] = useBookFromLocation();
	const navigate = useNavigate();

	console.log({ item });

	const locationChanged = (epubcifi) => {
		if (!firstRenderDone) {
			setLocation(AppLocalStorage.getItem("book-progress")); // getItem returns null if the item is not found.
			setFirstRenderDone(true);
			return;
		}
		AppLocalStorage.setItem("book-progress", epubcifi);
		setLocation(epubcifi);
		console.log(epubcifi);

		// Get current page
		if (renditionRef.current && tocRef.current) {
			const { href } = renditionRef.current.location.start;
			const chapter = tocRef.current.find((item) => item.href === href);
			setPage(`${chapter ? chapter.label : "n/a"}`);
			if (Number(chapter?.label?.slice(4)) > 3 && !item?.ebook) {
				openModal();
			}
		}
	};

	useEffect(() => {
		if (isShowModal) {
			setTimeout(() => {
				navigate("/utilisateur/accueil");
				closeModal();
			}, 30000);
		}
	}, [isShowModal]);

	const ownStyles = {
		...ReactReaderStyle,
		arrow: {
			...ReactReaderStyle.arrow,
			color: "#fff",
			background: "#000000",
			borderRadius: "50%",
			width: "30px",
			height: "30px",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			fontSize: "23px",
		},
	};

	return (
		<div className="dashbord-visiteur-component">
			<div className=" mb-3">
				<div className="yeewuna-parcours-utilisateur-titre-parcourir-container mb-4">
					<div className="yeewuna-tabs-container py-3">
						<div
							className="tabs-component-container myReader"
							style={{ height: "100vh" }}
						>
							<ReactReader
								location={location}
								locationChanged={locationChanged}
								readerStyles={ownStyles}
								title={item?.title}
								url={ApiBaseUrl + item?.epub}
								loadingView={
									<div
										style={{
											width: "100%",
											height: "100%",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<CircleSpinner />
									</div>
								}
								getRendition={(rendition) => (renditionRef.current = rendition)}
								tocChanged={(toc) => (tocRef.current = toc)}
							/>
						</div>
					</div>
				</div>
			</div>
			<BuyBookModal item={item} isOpen={isShowModal} closeModal={closeModal} />
		</div>
	);
}

export default LectureLivre;
