import { ChangeEvent, useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Book, BookFormData } from "../../../../../utils/api/book/book.type";
import {
	useAddOrEditBookMutation,
	useDeleteBookMutation,
} from "../../../../../utils/api/book/book.api";
import { useAppSelector } from "../../../../../redux/hooks";
import { Color } from "../../../../../utils/theme";
import { cleannerError } from "../../../../../utils/Utils";
import { QueryError } from "../../../../../utils/type";

function UseCrudBookForm(book?: Book) {
	const validationSchema = yup.object().shape({
		hasEdit: yup.boolean(),
		title: yup.string().required().label("Le titre"),
		category: yup.string().required().label("La catégorie"),
		langage: yup.string().required().label("La langue").nullable(),
		date_out: yup.string().required().label("La date de sortie"),
		description: yup.string().required().label("La description"),
		price: yup.string().required().label("Le prix"),
		couverture: yup.mixed().when("hasEdit", {
			is: false,
			then: yup
				.mixed()
				.required()
				.label("La photo de couverture")
				.test("Test val", "La photo de couverture est obligatoire", (val) => {
					// console.log("val", val);
					return val?.length === 0 ? false : true;
				}),
		}),
		epub: yup.mixed().when("hasEdit", {
			is: false,
			then: yup
				.mixed()
				.required()
				.label("Ebook")
				.test("Test val", "Ebook est obbligatoire", (val) => {
					// console.log("val", val);
					return val?.length === 0 ? false : true;
				}),
		}),
		ebook: yup.mixed().when("hasEdit", {
			is: false,
			then: yup
				.mixed()
				.required()
				.label("Le pdf")
				.test("Test val", "Le pdf est obligatoire", (val) => {
					// console.log("val", val);
					return val?.length === 0 ? false : true;
				}),
		}),
		pdf_price: yup.string().required().label("Le prix de l'ebook"),
		audio: yup.mixed().label("L'audiobook").nullable(),
		audio_price: yup.string().when("audio", {
			is: (val) => val?.length > 0,
			then: yup.string().required().label("Le prix de l'audiobook"),
		}),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<BookFormData>({
		resolver: yupResolver(validationSchema),
	});
	const navigate = useNavigate();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [files, setFiles] = useState<any>({
		couverture: null,
		ebook: null,
		audo: null,
	});
	const [description, setDescription] = useState("");
	const [loading, setLoading] = useState(false);
	const { user } = useAppSelector((s) => s?.user);
	const [sendData, { isLoading, isSuccess, error, isError }] = useAddOrEditBookMutation();

	const handleChangeFiles = (e: ChangeEvent<HTMLInputElement>) => {
		let filesCopy = { ...files };
		if (e?.target?.files?.length) {
			if (e.target.name === "couverture") {
				filesCopy["couverture"] = e?.target?.files[0];
				setValue("couverture", e?.target?.files[0]);
			} else if (e.target.name === "audio") {
				filesCopy["audio"] = e?.target?.files[0];
				setValue("audio", e?.target?.files[0]);
			} else if (e.target.name === "ebook") {
				filesCopy["ebook"] = e?.target?.files[0];
				setValue("ebook", e?.target?.files[0]);
			} else if (e.target.name === "epub") {
				filesCopy["epub"] = e?.target?.files[0];
				setValue("epub", e?.target?.files[0]);
			}
		}
		setFiles(filesCopy);
	};
	const handleChangeDescription = (val) => {
		setDescription(val);
		setValue("description", val);
	};
	useEffect(() => {
		if (book?.id) {
			console.log("book", book);
			const fields: (keyof BookFormData)[] = [
				"title",
				"description",
				"price",
				"audio_price",
				"langage",
				"date_out",
				"category",
				"slug",
				"author",
				"editeur",
				"isbn",
				"pdf_price",
			];
			for (let field of fields) {
				register(field);
				if (field === "category" || field === "author") {
					setValue(field, book[field]?.id);
				} else {
					setValue(field, book[field]);
				}
			}
			setValue("hasEdit", true);
			setDescription(book?.description);
		} else {
			setValue("hasEdit", false);
		}
	}, [book, register, setValue]);

	useEffect(() => {
		if (isSuccess) {
			setLoading(false);
			Swal.fire({
				icon: "success",
				iconColor: Color.success,
				confirmButtonColor: Color.success,
				title: book?.slug ? "Livre modifié avec succès !" : "Livre ajouté avec succès !",
				showConfirmButton: false,
				timer: 3000,
			}).then(() => {
				navigate("/auteur/livres");
			});
		}
		const err = error as QueryError;

		if (isError) {
			console.log("error", err);
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur de statut ${err?.status} est survenue`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [book?.slug, error, isError, isSuccess, navigate]);

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [clearErrors, errors]);

	// const convertToEpub = (file) => {
	// 	const wordsApi = new WordsApi("####-####-####-####-####", "##################");

	// 	const doc = fs.createReadStream(file);
	// 	const request = new model.ConvertDocumentRequest({
	// 		document: doc,
	// 		format: "epub",
	// 	});

	// 	const convert = wordsApi.convertDocument(request).then((convertDocumentResult) => {
	// 		console.log("Result of ConvertDocument: ", convertDocumentResult);
	// 	});
	// };

	const onSubmit = (data: BookFormData) => {
		if (data?.book_status === "drafted") {
			setLoading(true);
		}

		if (!book?.id) {
			data["author"] = user?.id;
		}
		console.log("data", data);
		const fd = new FormData();
		for (let key of Object.keys(data)) {
			if (data[key]) {
				if (key === "audio" || key === "couverture" || key === "ebook") {
					if (data[key]) {
						fd.append(key, data[key]);
					}
				} else {
					fd.append(key, data[key]);
				}
			}
		}
		return sendData({ slug: book?.slug, data: fd });
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		handleChangeDescription,
		description,
		loading,
		handleChangeFiles,
		files,
	};
}

export default UseCrudBookForm;

export function UseDeleteBookItem(slug: string) {
	const [deleteData] = useDeleteBookMutation();
	const onDelete = async () => {
		await Swal.fire({
			title: `Êtes-vous sûr de vouloir supprimer ce livre ?`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "OUI",
			cancelButtonText: "NON",
			showLoaderOnConfirm: true,
			iconColor: Color.themeColor,
			confirmButtonColor: Color.themeColor,
			preConfirm: () => {
				return deleteData(slug);
			},
			allowOutsideClick: () => !Swal.isLoading(),
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		}).then((result: any) => {
			if (result?.value) {
				console.log(result);
				Swal.fire({
					icon: "success",
					title: `Livre supprimé avec succès!`,
					iconColor: Color.themeColor,
					showConfirmButton: false,
					timer: 1500,
				});
			}
		});
	};
	return onDelete;
}
