import React from "react";
import LivreItem, { LivreItemSkeleton } from "../Accueil/LivreItem";
import { AutheurDescriptionItem, LivredescriptionItem } from "./DetailLivreComponents";
import { Link } from "react-router-dom";
import { useGetPublishedBooksByAuthor } from "../Autheurs/hooks/useAuthor";
import { AlertInfo } from "../../../common/Alert";
import MapSkeleton from "../../../common/MapSkeleton";
import { useBookFromLocation } from "../../../../utils/api/book/book.api";
import { GoBackBtn } from "../../../common/Button";

function DetailsLivre() {
	const [item] = useBookFromLocation();
	const { books, isLoading } = useGetPublishedBooksByAuthor();
	console.log({ item });
	return (
		<>
			<div className="user-dash-page-content-container pb-5 mb-3">
				<div className="yeewuna-details-actu-container px-0">
					<div className="d-flex mb-5">
						<GoBackBtn />
						<h3 className="yeewuna-description-livre-titre m-0">
							Description du livre
						</h3>
					</div>
					<LivredescriptionItem item={item} />
					<AutheurDescriptionItem author={item?.author} />
				</div>
			</div>
			<div className="user-dash-page-content-container pb-5 mb-3">
				<div className="yeewuna-details-actu-container px-0">
					<div className="yeewuna-detail-autheur-ouvres-container">
						<div className="detail-livre-btn-voir-plus-container">
							<div className="yeewuna-detail-autheur-titre-a-propos mb-4">
								Autres livres du même auteur
							</div>
							<Link to="/utilisateur/accueil" className="detail-livre-btn-voir-plus">
								Voir plus
							</Link>
						</div>
						<div className="row">
							{!isLoading ? (
								<>
									{books && books?.length > 0 ? (
										books.map((item) => (
											<div
												className="col-6 col-md-3 col-xl-6"
												key={item?.slug}
											>
												<LivreItem item={item} />
											</div>
										))
									) : (
										<AlertInfo message="Aucune donnée trouvée" size="small" />
									)}
								</>
							) : (
								<MapSkeleton total={4}>
									<LivreItemSkeleton />
								</MapSkeleton>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default DetailsLivre;
