import React from "react";
import { useRoutes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { AppRoutes } from "./routes/router";
import { useAppSelector } from "./redux/hooks";

function FetchUser() {
	// const { isSuccess, data } = useMeQuery();
	// const dispatch = useAppDispatch();
	// useEffect(() => {
	// 	if (isSuccess) {
	// 		console.log(data);
	// 		dispatch(onSetUser(data));
	// 	}
	// }, [isSuccess, data]);

	return null;
}

function App() {
	const routes = useRoutes(AppRoutes);
	const isConnected = useAppSelector((s) => !!s.user?.token);

	return (
		<div className="app-routing-container">
			{isConnected && <FetchUser />}
			{routes}
		</div>
	);
}

export default App;
