import React, { useEffect, useState } from "react";
import { AiFillEdit, AiFillHeart } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import "./ProfilAuteur.css";
import { useStepType } from "../../../../utils/type";
import { useStep } from "react-hooks-helper";
import AboutMe from "./ProfilTabs/AboutMe";
import Brouillon from "./ProfilTabs/Brouillon";
import Abonnement from "./ProfilTabs/Abonnement";
import { formatFollowerNumber, getAvatar, getImage } from "../../../../utils/Utils";
import ListAbonnes from "./ProfilTabs/ListAbonnes";
import { IUser } from "../../../../utils/api/user/user.type";
import { useLazyGetUserBySlugQuery } from "../../../../utils/api/user/user.api";
import { useAppSelector } from "../../../../redux/hooks";
const steps = [
	{ id: "a-propos", Component: AboutMe },
	{ id: "brouillon", Component: Brouillon },
	{ id: "abonnements", Component: Abonnement },
	{ id: "abonnes", Component: ListAbonnes },
];
const tabs = ["A propos de moi", "Mon brouillon", "Mes abonnements", "Mes Abonnés"];

const ProfilAuteur = () => {
	const {
		step,
		navigation: { go },
		index,
	}: useStepType = useStep({ initialStep: 0, steps });
	const { Component } = step;
	const { user: currentUser } = useAppSelector((s) => s?.user);
	const [user, setUser] = useState<IUser>(currentUser);
	const [findBySlug] = useLazyGetUserBySlugQuery();
	console.log(user);

	const findUser = async () => {
		const res = await findBySlug(currentUser?.slug as string);
		if ("data" in res) {
			setUser(res.data as IUser);
		}
	};

	useEffect(() => {
		if (currentUser) {
			findUser();
		}
	}, [currentUser]);

	let bgImg = user?.banniere ? getImage(user?.banniere) : "";
	return (
		<div className="list-admin-admin">
			<div className="container-title-page-admin">
				<div className="content-title-admin">
					<h3 className="h3-title-admin">Mon profil</h3>
				</div>
				<div className="content-btn-action-title-page-admin">
					<NavLink to="/auteur/modifier-profil" className="btn btn-page-title-admin">
						<AiFillEdit />
						<span className="ps-2">Modifier mon profil</span>
					</NavLink>
				</div>
			</div>
			<div className="bg-white mt-4">
				<div
					className="container-profil-auteur mb-5"
					style={!!bgImg ? { backgroundImage: `url(${bgImg})` } : {}}
				>
					<div className="content-pp-profil-auteur">
						<div className="content-img-profil-auteur-dash pb-3 text-center">
							<img
								src={getAvatar(user?.avatar)}
								alt="Photos de profil"
								className="img-profil-auteur-dash"
							/>
						</div>
						<h3 className="name-profil-auteur-dash">
							{user?.first_name + " " + user?.last_name}{" "}
						</h3>
						<div className="container-info-profil-auteur-dash">
							<p className="info-item-profil-auteur-dash mb-0">
								{user?.published_books_count ?? 0}{" "}
								<span style={{ fontWeight: 400, fontSize: 16 }}>oeuvres</span>
							</p>
							<p className="info-item-profil-auteur-dash mb-0">
								{formatFollowerNumber(user?.subscribers?.length)}{" "}
								<span style={{ fontWeight: 400, fontSize: 16 }}>abonnés</span>
							</p>
							<p className="info-item-profil-auteur-dash mb-0">
								{formatFollowerNumber(user?.favorite_books_count)}
								<span style={{ fontSize: 26 }}>
									<AiFillHeart />
								</span>
							</p>
						</div>
					</div>
				</div>
				<div className="admin-table">
					<div className="setting-admin">
						<div className="container-tabs-admin-setting">
							<ul className="tabs-container">
								{tabs.map((tab, key) => (
									<li
										className={`tab-item  ${
											index === key && "tab-active-item"
										}`}
										key={key}
										onClick={() => go(key)}
										role="button"
									>
										{tab}
									</li>
								))}
							</ul>
						</div>
						<div className="tabs-component-container pt-4">
							<Component />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfilAuteur;
