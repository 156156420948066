import moment from "moment";
import React from "react";
import { BsTrashFill } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import { IUser } from "../../../../utils/api/user/user.type";
import { createMarkup, getAvatar, useLocationState } from "../../../../utils/Utils";
import { UseArchiveUserItem } from "../GestionAuteurs/requestAuthorForm/UseCrudAuthorForm";
import { AlertInfo } from "../../../common/Alert";

function DeleteItemBtn({ item }: { item: IUser }) {
	const onDelete = UseArchiveUserItem(item);

	return (
		<button className="btn btn-delete-page-title-admin" onClick={() => onDelete()}>
			{!item?.is_archived ? <BsTrashFill /> : <FaTrash />}
			<span className="ps-2">
				{" "}
				{!item?.is_archived ? "Archiver" : "Désartiver"} cet utilisateur
			</span>
		</button>
	);
}
const UserDetail = () => {
	const item = useLocationState<IUser>(undefined);
	console.log({ item });
	return (
		<div className="list-users-admin">
			<div className="container-title-page-admin">
				<div className="content-title-admin">
					<h3 className="h3-title-admin">Détails</h3>
				</div>
				<div className="content-btn-action-title-page-admin">
					<DeleteItemBtn item={item} />
				</div>
			</div>
			<div className="detail-user-page mt-4">
				<div className="row row-user-detail">
					<div className="col-lg-4 col-md-6 col-detail-user mb-3 d-flex">
						<div className="container-admin-page-table w-100">
							<div className="content-img-profil-user-detail text-center pb-3">
								<img
									src={getAvatar(item?.avatar)}
									alt="Profil"
									className="admin-img-pp-user"
								/>
							</div>
							<h4 className="admin-title-user-profil text-center">
								{item?.first_name + " " + item?.last_name}
							</h4>
							<div className="content-text-admin-profil-user">
								{!!item?.bio && (
									<p
										className="text-admin-profil-user"
										dangerouslySetInnerHTML={createMarkup(item?.bio)}
									/>
								)}
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-6 col-detail-user mb-3 d-flex">
						<div className="container-admin-page-table w-100">
							<h4 className="admin-title-user-profil b-b-1 pb-3">
								Détails personnels
							</h4>
							<div className="content-detail-user-info mt-4">
								<div className="detail-user-info-item pb-3">
									<p className="text-detail-user-libelle mb-0">
										Date d'inscription:
									</p>
									<p className="text-detail-user-value mb-0">
										{moment(item?.date_joined).format("DD MMMM YYYY")}
									</p>
								</div>
								<div className="detail-user-info-item pb-3">
									<p className="text-detail-user-libelle mb-0">Adresse:</p>
									<p className="text-detail-user-value mb-0">{item?.adress}</p>
								</div>
								<div className="detail-user-info-item pb-3">
									<p className="text-detail-user-libelle mb-0">Téléphone:</p>
									<p className="text-detail-user-value mb-0">{item?.phone}</p>
								</div>
								<div className="detail-user-info-item pb-3">
									<p className="text-detail-user-libelle mb-0">Email:</p>
									<p className="text-detail-user-value mb-0">{item?.email}</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-6 col-detail-user mb-3 d-flex">
						<div className="container-admin-page-table w-100">
							<h4 className="admin-title-user-profil b-b-1 pb-3">
								Top catégories{" "}
								<span
									style={{
										color: "rgba(0, 0, 0, 0.6)",
										fontSize: 14,
										fontWeight: 400,
									}}
								>
									des livres achetés
								</span>
							</h4>
							<div className="conatiner-progress mt-4">
								{item?.top_five_catogories &&
								item?.top_five_catogories?.length > 0 ? (
									item?.top_five_catogories?.map((item) => (
										<div className="progress-item pb-3" key={item?.slug}>
											<p className="title-progress-bar-detail-user-admin mb-2">
												{item?.name}
											</p>
											{/* <div className="progress">
										<div
											className="progress-bar"
											role="progressbar"
											aria-valuenow={60}
											style={{ width: "60%" }}
										></div>
									</div> */}
										</div>
									))
								) : (
									<AlertInfo message="Aucun livre acheté par cet utilisateur" />
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserDetail;
