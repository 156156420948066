/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { cleannerError } from "../../../../../utils/Utils";
import { UserFormData } from "../../../../../utils/api/user/user.type";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { onSetUser } from "../../../../../redux/slice/User.slice";
import { Color } from "../../../../../utils/theme";
import { useEditUserMutation } from "../../../../../utils/api/user/user.api";
import { QueryError } from "../../../../../utils/type";

function UseEditUserInfos() {
	const validationSchema = yup.object().shape({
		first_name: yup.string().required().label("Le prénom"),
		last_name: yup.string().required().label("Le nom"),
		adress: yup.string().label("L'adresse").nullable(),
		// phone: yup.string().required().label("Le téléphone"),
		email: yup.string().email().required().label("L'email"),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<UserFormData>({
		resolver: yupResolver(validationSchema),
	});
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((s) => s?.user);

	const [editUser, { isLoading, isSuccess, error, isError, data }] = useEditUserMutation();
	const [bio, setBio] = useState<string>("");
	const [avatar, setAvatar] = useState<any>(null);
	const [banner, setBanner] = useState<any>(null);

	const handleChangeAvatar = (e: ChangeEvent<HTMLInputElement>) => {
		if (e?.target?.files?.length) {
			setAvatar(e?.target?.files[0]);
			setValue("avatar", e?.target?.files[0]);
		}
	};

	const handleChangeBanner = (e: ChangeEvent<HTMLInputElement>) => {
		if (e?.target?.files?.length) {
			setBanner(e?.target?.files[0]);
			setValue("banniere", e?.target?.files[0]);
		}
	};

	const handleChangeBio = (val) => {
		setBio(val);
		setValue("bio", val);
	};

	useEffect(() => {
		if (user?.slug) {
			const fields: (keyof UserFormData)[] = [
				"first_name",
				"last_name",
				"email",
				"phone",
				"slug",
				"bio",
				"adress",
			];
			for (let field of fields) {
				register(field);
				setValue(field, user[field]);
			}

			if (user?.bio) {
				setBio(user?.bio);
			}

			if (user?.phone) {
				// setPhone(user?.phone);
			}
		}
	}, [register, setValue, user]);

	useEffect(() => {
		if (isSuccess) {
			// console.log("data", data);
			if (data) {
				dispatch(onSetUser(data));
				Swal.fire({
					icon: "success",
					iconColor: Color.success,
					confirmButtonColor: Color.success,
					title: "Informations modifiées avec succès!",
					showConfirmButton: false,
					timer: 3000,
				}).then(() => {
					if (user?.user_type === "author") {
						navigate("/auteur/profil");
					} else if (user?.user_type === "reader") {
						navigate("/utilisateur/mon-profil");
					} else {
						navigate("/admin/profil");
					}
				});
			}
		}
		const err = error as QueryError;

		if (isError) {
			console.log("error", err);

			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur de statut ${err?.status} est survenue`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [data, dispatch, error, isError, isSuccess, navigate]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [clearErrors, errors]);

	const onSubmit = async (data: UserFormData) => {
		const fd = new FormData();
		for (let key of Object.keys(data)) {
			if (data[key]) {
				if (key === "avatar" || key === "banniere") {
					if (data[key]) {
						fd.append(key, data[key]);
					}
				} else {
					fd.append(key, data[key]);
				}
			}
		}
		await editUser({ slug: data?.slug, data: fd });
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		// phone,
		// handleChangePhone,
		avatar,
		handleChangeAvatar,
		bio,
		handleChangeBio,
		handleChangeBanner,
		banner,
	};
}

export default UseEditUserInfos;
