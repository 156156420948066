import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import VolkenoSkeleton from "volkeno-react-skeleton";
import { useGetAuthorsQuery } from "../../../../../utils/api/user/user.api";
import { usePagination } from "../../../../../utils/hooks";
import { formatFollowerNumber, getImage, isShowPagination } from "../../../../../utils/Utils";
import { AlertInfo } from "../../../../common/Alert";
import { CustomPagination } from "../../../Auteur/GestionLivreAuteur/ListLivresAuteurTable/ListLivresAuteurTable";

function ListeAuteurs({ search }: { search?: string }) {
	const { limit, page, setPage, setPerPage } = usePagination(12);
	const { data, isLoading } = useGetAuthorsQuery({ page, limit, q: search });
	let location = useLocation();
	return (
		<div className="yeewuna-parcours-utilisateur-liste-auteur-container my-3">
			<div className="row">
				{!isLoading ? (
					data && data?.results?.length > 0 ? (
						data?.results?.map((item) => (
							<div className="col-md-3 mb-4" key={item?.slug}>
								<NavLink
									to={`${
										location?.pathname?.startsWith("/auteur")
											? "/auteur/liste-des-auteurs"
											: "/utilisateur/auteur"
									}/${item?.slug}`}
									className="no-link"
									state={item}
								>
									<div className="yeewuna-parcours-utilisateur-auteur-item-container">
										<img
											src={getImage(item?.avatar)}
											alt="photos de l'autheur"
											className="yeewuna-parcours-utilisateur-auteur-item-image"
										/>
										<div className="yeewuna-parcours-utilisateur-auteur-item-body-container mt-2">
											<div className="yeewuna-parcours-utilisateur-auteur-item-username">
												{item?.first_name + " " + item?.last_name}{" "}
												<span>{item?.published_books?.length} livres</span>
											</div>
											<div className="yeewuna-parcours-utilisateur-auteur-item-abonne">
												{formatFollowerNumber(item?.subscribers?.length)}{" "}
												abonnés
											</div>
										</div>
									</div>
								</NavLink>
							</div>
						))
					) : (
						<AlertInfo message="Aucune donnée trouvée" />
					)
				) : (
					<>
						{[...Array(5)]?.map((item, i) => (
							<div className="col-md-3 mb-4" key={i}>
								<div className="yeewuna-parcours-utilisateur-auteur-item-container">
									<VolkenoSkeleton
										variant="rect"
										className="yeewuna-parcours-utilisateur-auteur-item-image"
									/>
									<div className="mt-2">
										<VolkenoSkeleton variant="rect" width="100%" height={10} />
										<div className="yeewuna-parcours-utilisateur-auteur-item-abonne">
											<VolkenoSkeleton
												variant="rect"
												width="100%"
												height={10}
											/>
										</div>
									</div>
								</div>
							</div>
						))}
					</>
				)}
				{isShowPagination(data?.count || 0, limit) && (
					<CustomPagination
						nbPages={data?.count}
						page={page}
						onChange={(page, perPage) => {
							setPerPage(perPage);
							setPage(page);
						}}
						perPage={limit}
					/>
				)}
			</div>
		</div>
	);
}

export default ListeAuteurs;
