/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { RiEdit2Fill } from "react-icons/ri";
import "./ListUsersTable.css";
import TableSkeleton from "../../../../common/TableSkeleton";
import { useGetReadersQuery } from "../../../../../utils/api/user/user.api";
import { NavLink } from "react-router-dom";
import { DeleteItemBtn } from "../../GestionAuteurs/ListAuteursTable/ListAuteursTable";
import { IUser } from "../../../../../utils/api/user/user.type";
import { formatDate, getAvatar } from "../../../../../utils/Utils";
import { CustomPagination } from "../../../Auteur/GestionLivreAuteur/ListLivresAuteurTable/ListLivresAuteurTable";
import { AiFillEye } from "react-icons/ai";
function ListUsersTable() {
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const { data = { results: [], count: 0 }, isLoading } = useGetReadersQuery({
		page,
		limit,
	});
	// console.log({ data });

	const actionFormatter = (cell: any, row: any) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<div className="container-btn-action-icon-modal">
						<NavLink
							to={`/admin/utilisateur/${cell}`}
							state={row}
							className="btn btn-action-icon-edit with-tooltip"
							data-tooltip-content="Voir"
						>
							<AiFillEye />
						</NavLink>
					</div>
					<div className="container-btn-action-icon-modal">
						<NavLink
							to={`/admin/modifier-utilisateur/${cell}`}
							state={row}
							className="btn btn-action-icon-edit with-tooltip"
							data-tooltip-content="Modifier"
						>
							<RiEdit2Fill />
						</NavLink>
					</div>
					<div className="container-btn-action-icon-modal">
						<DeleteItemBtn item={row} />
					</div>
				</div>
			</>
		);
	};
	const nameFormatter = (cell: string, row: IUser) => {
		return (
			<div className="table-actions-btn-container-img gap-2 d-flex align-items-center">
				<div className="content-img-pp-auteur-admin-table">
					<img
						src={getAvatar(row?.avatar)}
						alt={cell}
						className="photo-profil-auteur-admin-table"
					/>
				</div>{" "}
				<p className="name-auteur-admin-table mb-0">{cell + " " + row?.last_name}</p>
			</div>
		);
	};

	const columns = [
		{
			dataField: "first_name",
			text: "Noms",
			formatter: (cell: any, row: any) => nameFormatter(cell, row),
		},
		{
			dataField: "email",
			text: "Email",
		},
		{
			dataField: "phone",
			text: "Téléphone",
		},
		{
			dataField: "date_joined",
			text: "Date d’inscription",
			formatter: (cell: string) => formatDate(cell),
		},
		{
			dataField: "slug",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: any, row: any) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						// selectRow={selectRow}
						// rowEvents={rowEvents}
						// rowClasses={rowClasses}
						noDataIndication={() => "Aucune donnée trouvée"}
					/>
					<CustomPagination
						nbPages={data?.count}
						page={page}
						onChange={(page, perpage) => {
							setPage(page);
							setLimit(perpage);
						}}
						perPage={limit}
					/>
				</>
			)}
		</>
	);
}

export default ListUsersTable;
