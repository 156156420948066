import React from "react";
import "./MonProfile.css";
import { NavLink } from "react-router-dom";
import { IoCallOutline, IoLocationSharp } from "react-icons/io5";
import { AiOutlineMail } from "react-icons/ai";
import { useAppSelector } from "../../../../redux/hooks";
import { createMarkup, getAvatar, getName } from "../../../../utils/Utils";
import { isAdminUser } from "../../../../routes/routerUtils";
import { FiEdit } from "react-icons/fi";

function MonProfile() {
	const { user } = useAppSelector((s) => s?.user);
	return (
		<div className="list-admins-admin">
			<div className="container-title-page-admin">
				<div className="content-title-admin">
					<h3 className="h3-title-admin">Mon profil</h3>
				</div>
			</div>
			<div className="container-profil-admin mt-4">
				<div className="card-profil-admin mb-3">
					<div className="container-info-user-profil">
						<div className="content-pp-profil-admin text-center py-3">
							<img
								src={getAvatar(user?.avatar)}
								alt="profil utilisateur"
								className="pp-profil-user-admin"
							/>
						</div>
						<p className="name-pp-user-profil-admin text-center">{getName(user)}</p>
						<div
							className="text-pp-user-profil-admin user-bio-content"
							dangerouslySetInnerHTML={createMarkup(user?.bio)}
						/>
						<div className="info-user-profil-btn-edit-container">
							<NavLink
								to={"/utilisateur/modifier-profil"}
								className="btn btn-edir-profil px-3 d-none d-md-block"
							>
								Editer mon profil
							</NavLink>
							<NavLink
								to={"/utilisateur/modifier-profil"}
								className="btn btn-edir-profil px-3 d-md-none"
								style={{ position: "absolute", top: "-30px", right: "-30px" }}
							>
								<FiEdit />
							</NavLink>
						</div>
					</div>
					{/* <div className="container-user-info-contact-profil-utilisateur mt-5 mb-4">
						<div className="user-info-contact-profil-utilisateur-statistique">
							<span>1254</span> abonnements
						</div>
						<div className="user-info-contact-profil-utilisateur-statistique">
							<span>1254</span> abonnés
						</div>
						<div className="user-info-contact-profil-utilisateur-statistique">
							<span>1254</span> likes
						</div>
					</div> */}
					<div className="container-user-info-contact-profil-utilisateur-deux mt-5 mb-4">
						<div className="content-info-contact-profil-admin">
							<p className="libelle-text-contact-profil-admin">Adresse</p>
							<div className="content-contact-profil-admin">
								<a href="/#" className="btn-action-admin">
									<IoLocationSharp />
								</a>
								<p className="text-contact-profil-admin">{user?.adress}</p>
							</div>
						</div>
						{!!isAdminUser(user) && (
							<div className="content-info-contact-profil-admin">
								<p className="libelle-text-contact-profil-admin">Téléphone</p>
								<div className="content-contact-profil-admin">
									<a href="/#" className="btn-action-admin">
										<IoCallOutline />
									</a>
									<p className="text-contact-profil-admin">{user?.phone}</p>
								</div>
							</div>
						)}
						<div className="content-info-contact-profil-admin">
							<p className="libelle-text-contact-profil-admin">Email</p>
							<div className="content-contact-profil-admin">
								<a href="/#" className="btn-action-admin">
									<AiOutlineMail />
								</a>
								<p className="text-contact-profil-admin">{user?.email}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MonProfile;
