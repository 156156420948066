import React from "react";
import DevisTable from "./DevisTable";

const Devis = () => {
	return (
		<div className="list-admin-admin">
			<div className="container-title-page-admin mb-3">
				<div className="content-title-admin">
					<h3 className="h3-title-admin">Démandes de devis</h3>
				</div>
			</div>
			<DevisTable />
		</div>
	);
};

export default Devis;
