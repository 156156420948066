import React from "react";
import { useGetFaqQuery } from "../../../../utils/api/faq/faq.api";
import { IFaq } from "../../../../utils/api/faq/faq.type";
import { AlertInfo } from "../../../common/Alert";
import { ButtonAdd } from "../../../common/Button";
import { FaqItem, FaqItemSkeleton } from "./FaqItems";
import { useModal, usePagination } from "../../../../utils/hooks";
import { isShowPagination } from "../../../../utils/Utils";
import { CustomPagination } from "../../Auteur/GestionLivreAuteur/ListLivresAuteurTable/ListLivresAuteurTable";
import AddEditFaqModal from "./AddEditFaqModal";

const FaqAdmin = () => {
	const { limit, page, setPage, setPerPage } = usePagination(10);
	const { data, isLoading } = useGetFaqQuery({ limit, page });
	const { isShowModal, item, openModal, openEditModal, closeModal } = useModal<IFaq>();

	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page">
				<div className="col-left-dashboard col-left-dashboard-responsive mb-3">
					<div className="goana-dash-header">
						<h4 className="goana-dash-header-titre">Faq</h4>
					</div>
					<div className="bg-white p-3 mt-4 filter-and-table-container">
						<div className="container-head-admin mb-4">
							<div className="flex-r mb-5">
								<ButtonAdd label="Ajouter une question" onClick={openModal} />
							</div>
						</div>
						<div className="container-coolapse-questions mt-5">
							{!isLoading ? (
								data && data?.results?.length > 0 ? (
									<>
										{data?.results?.map((item) => (
											<FaqItem
												item={item}
												key={item?.slug}
												openEditModal={openEditModal}
											/>
										))}
										{isShowPagination(data?.count || 0, limit) && (
											<CustomPagination
												nbPages={data?.count}
												page={page}
												onChange={(page, perPage) => {
													setPerPage(perPage);
													setPage(page);
												}}
												perPage={limit}
											/>
										)}
									</>
								) : (
									<AlertInfo message="Aucune donnée trouvée" />
								)
							) : (
								<>
									{[...Array(5)].map((item, i) => (
										<FaqItemSkeleton key={i} />
									))}
								</>
							)}
						</div>
					</div>
				</div>
				<AddEditFaqModal isOpen={isShowModal} item={item} closeModal={closeModal} />
			</div>
		</div>
	);
};
export default FaqAdmin;
