/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { IUser, UserFormData } from "../../../../../utils/api/user/user.type";
import { useAppSelector } from "../../../../../redux/hooks";
import { useAddOrEditUserMutation } from "../../../../../utils/api/user/user.api";
import { Color } from "../../../../../utils/theme";
import { cleannerError } from "../../../../../utils/Utils";
function UseCrudUserForm(reader?: IUser) {
	const validationSchema = yup.object().shape({
		first_name: yup.string().required().label("Le prénom"),
		last_name: yup.string().required().label("Le nom"),
		address: yup.string().label("L'adresse").nullable(),
		phone: yup.string().required().label("Le téléphone"),
		email: yup.string().email().required().label("L'email"),
		avatar: yup.mixed().nullable().label("La photo"),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<UserFormData>({
		resolver: yupResolver(validationSchema),
	});
	const navigate = useNavigate();
	const { user } = useAppSelector((s) => s?.user);

	const [sendData, { isLoading, isSuccess, error, isError, data }] = useAddOrEditUserMutation();
	const [avatar, setAvatar] = useState<any>(null);
	const [phone, setPhone] = useState<string | null>(null);
	const handleChangeAvatar = (e: ChangeEvent<HTMLInputElement>) => {
		if (e?.target?.files?.length) {
			setAvatar(e?.target?.files[0]);
			setValue("avatar", e?.target?.files[0]);
		}
	};
	const handleChangePhone = (phone: string) => {
		setValue("phone", phone.slice(3));
		setPhone(phone);
	};
	useEffect(() => {
		if (reader?.slug) {
			const fields: (keyof UserFormData)[] = [
				"first_name",
				"last_name",
				"email",
				"phone",
				"slug",
				"bio",
				"adress",
			];
			for (let field of fields) {
				register(field);
				setValue(field, reader[field]);
			}
		}
	}, [reader, register, setValue]);

	useEffect(() => {
		if (isSuccess) {
			// console.log("data", data);
			if (data) {
				Swal.fire({
					icon: "success",
					iconColor: Color.success,
					confirmButtonColor: Color.success,
					title: !reader
						? "Utilisateur ajouté avec succès!"
						: "Informations modifiées avec succès!",
					showConfirmButton: false,
					timer: 3000,
				}).then(() => {
					navigate("/admin/utilisateurs");
				});
			}
		}
		const err = error as any;

		if (isError) {
			console.log("error", err);

			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur de statut ${err?.status} est survenue`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [data, error, isError, isSuccess, navigate]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [clearErrors, errors]);

	const onSubmit = async (data: UserFormData) => {
		if (!reader) {
			data["user_type"] = "reader";
		}
		const fd = new FormData();
		for (let key of Object.keys(data)) {
			const value = data[key];
			fd.append(key, value);
		}
		await sendData({ slug: data?.slug, userType: user?.user_type, data: fd });
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		avatar,
		handleChangeAvatar,
		phone,
		handleChangePhone,
	};
}

export default UseCrudUserForm;
