import React from "react";
import { HiOutlineCamera } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
// import Profil from "../../../../assets/appImages/imagefatou.png";
import ReactQuill from "react-quill";
import { useAppSelector } from "../../../../redux/hooks";
import { getAvatar, getImage, getInputAvatar } from "../../../../utils/Utils";
import { InputAdmin } from "../../../common/Input";
import UseEditUserInfos from "./requestUser/UseEditUserInfos";

const EditProfilAuteur = () => {
  const navigate = useNavigate();
  const { user } = useAppSelector((s) => s?.user);
  const {
    register,
    onSubmit,
    errors,
    avatar,
    handleChangeBanner,
    banner,
    handleChangeAvatar,
    isLoading,
    bio,
    handleChangeBio,
  } = UseEditUserInfos();

  let bgImg = banner
    ? getInputAvatar(banner)
    : user?.banniere
    ? getImage(user?.banniere)
    : "";

  return (
		<div className="list-admin-admin">
			<div className="container-title-page-admin">
				<div className="content-title-admin">
					<h3 className="h3-title-admin">Mon profil</h3>
				</div>
			</div>
			<div className="bg-white mt-4">
				<form onSubmit={onSubmit}>
					<div
						className="container-profil-auteur mb-5 position-relative"
						style={!!bgImg ? { backgroundImage: `url(${bgImg})` } : {}}
					>
						<div className="content-pp-profil-auteur">
							<div className="content-change-img-background ">
								<label
									htmlFor="input-banner"
									className="input-change-avatar-auteur-profil"
								>
									<span className="text-change-img-bg linkable">
										Modifier l’image d’arrière plan <HiOutlineCamera />{" "}
									</span>
								</label>
								<input
									type="file"
									className="input-add-avatar"
									id="input-banner"
									accept="image/*"
									onChange={(e) => handleChangeBanner(e)}
								/>
							</div>
							<div className="content-img-profil-auteur-dash-edit pb-3">
								<div className="change-img-profil-auteur-dash">
									<label
										htmlFor="input-test"
										className="input-change-avatar-auteur-profil"
									>
										<img
											src={
												avatar
													? getInputAvatar(avatar)
													: getAvatar(user?.avatar)
											}
											alt="profil utilisateur"
											className="pp-profil-user-auteur"
										/>
										<div className="auteur-user-avatar-edit-hover">
											<HiOutlineCamera className="content-icon-change-photo-pp-auteur-profil" />
										</div>
									</label>
									<input
										type="file"
										className="input-add-avatar"
										id="input-test"
										accept="image/*"
										onChange={(e) => handleChangeAvatar(e)}
									/>
								</div>
							</div>
							<div className="content-input-change-name-auteur-profil text-center">
								{/* <input
                  type="text"
                  className="form-control input-change-name-auteur-profil"
                  placeholder="Fatou Diom"
                /> */}
								<h3 className="name-profil-auteur-dash">
									{user?.first_name + " " + user?.last_name}{" "}
								</h3>
							</div>
						</div>
					</div>
					<div className="container-auteur-profil-edit">
						<div className="row row-auteur-profil-edit px-2">
							<div className="col-md-6 col-edit-profil-auteur">
								<div className="content-col-edit-profil-auteur mb-3">
									<InputAdmin
										label="Prénom"
										type={"text"}
										placeholder="Prénom"
										{...register("first_name")}
										error={errors?.first_name?.message}
									/>
								</div>
								<div className="content-col-edit-profil-auteur mb-3">
									<InputAdmin
										label="Email"
										type={"email"}
										placeholder="Email"
										{...register("email")}
										error={errors?.email?.message}
									/>
								</div>
								<div className="content-col-edit-profil-auteur mb-3">
									<InputAdmin
										label="Lieu"
										type={"text"}
										placeholder="Emplacement"
										{...register("adress")}
										error={errors?.adress?.message}
									/>
								</div>
							</div>
							<div className="col-md-6 col-edit-profil-auteur">
								<div className="content-col-edit-profil-auteur mb-3">
									<InputAdmin
										label="Nom"
										type={"text"}
										placeholder="Nom"
										{...register("last_name")}
										error={errors?.last_name?.message}
									/>
								</div>
								<div className="content-col-edit-profil-auteur mb-3">
									<InputAdmin
										label="Téléphone"
										type={"tel"}
										placeholder="Téléphone"
										required
										{...register("phone")}
										error={errors?.phone?.message}
									/>
								</div>
							</div>
							<div className="col-md-12">
								<div className="content-col-edit-profil-auteur mb-3">
									<label htmlFor="bio" className="form-label form-label-admin">
										Bio
									</label>
									{/* <textarea
                    name="bio"
                    id="bio"
                    rows={6}
                    className="form-control form-control-admin"
                  ></textarea> */}
									<ReactQuill
										theme="snow"
										value={bio}
										onChange={handleChangeBio}
										className="profil-bio"
									/>
								</div>
							</div>
							<div className="col-md-12 text-end">
								<div className="row">
									<div className="col-lg-4 offset-lg-4 col-md-6 mb-3 pt-4">
										<div className="content-btn-form-admin">
											<button
												className="btn auth-submit-annuler w-100"
												type="button"
												onClick={() => navigate(-1)}
											>
												Annuler
											</button>
										</div>
									</div>
									<div className="col-lg-4 col-md-6 mb-3 pt-4">
										<div className="content-btn-form-admin">
											<button
												className="btn btn-page-title-admin w-100"
												disabled={isLoading}
											>
												{!!isLoading ? (
													<>
														<span
															className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
															role="status"
														></span>
														<span>Enregistrement...</span>
													</>
												) : (
													"Enregistrer"
												)}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
  );
};

export default EditProfilAuteur;
