import React from "react";
import { useUserFromLocation } from "../../../../utils/api/user/user.api";
import { getAvatar, getInputAvatar } from "../../../../utils/Utils";
import { FormError, InputAdmin } from "../../../common/Input";
import UseCrudUserForm from "./requestUserForm/UseCrudUserForm";
import { GoBackBtn } from "../../../common/Button";
import PhoneInput from "react-phone-input-2";

const AjouterUser = () => {
	const [item] = useUserFromLocation();
	const {
		register,
		errors,
		onSubmit,
		isLoading,
		avatar,
		handleChangeAvatar,
		phone,
		handleChangePhone,
	} = UseCrudUserForm(item);
	return (
		<div className="add-auteur-component">
			<div className="container-title-page-admin">
				<div className="content-title-admin itm-center">
					<GoBackBtn />
					<h3 className="h3-title-admin">
						{item ? "Modifier" : "Ajouter"} un utilisateur
					</h3>
				</div>
			</div>
			<div className="container-form-admin mt-5 bg-white p-3">
				<form onSubmit={onSubmit}>
					<div className="row row-form-admin">
						<div className="col-md-6 col-form-admin mb-3">
							<div className="content-form-admin">
								<InputAdmin
									id="nom"
									label="Nom"
									placeholder="Nom"
									type={"text"}
									required
									{...register("last_name")}
									error={errors?.last_name?.message}
								/>
							</div>
						</div>
						<div className="col-md-6 col-form-admin mb-3">
							<div className="content-form-admin">
								<InputAdmin
									id="prenom"
									label="Prénom"
									placeholder="Prénom"
									type={"text"}
									required
									{...register("first_name")}
									error={errors?.first_name?.message}
								/>
							</div>
						</div>
						<div className="col-md-6 col-form-admin mb-3">
							<div className="content-form-admin">
								<InputAdmin
									id="email"
									label="Email"
									placeholder="Email"
									type={"email"}
									required
									{...register("email")}
									error={errors?.email?.message}
								/>
							</div>
						</div>
						<div className="col-md-6 col-form-admin mb-3">
							<div className="content-form-admin">
								<label
									htmlFor="phone"
									className="form-label form-label-admin"
									aria-labelledby="phone"
								>
									Téléphone
									<span
										className="text-danger custom-required-text"
										style={{ fontSize: 17 }}
									>
										*
									</span>
								</label>
								<PhoneInput
									country={"sn"}
									value={phone}
									onChange={handleChangePhone}
									onlyCountries={["sn"]}
									masks={{ sn: ".. ... .. .." }}
									inputClass="form-control-modal-custom input-custom"
								/>
								<FormError error={errors.phone} />
							</div>
						</div>
						<div className="col-md-6 col-form-admin mb-3">
							<div className="content-form-admin">
								<InputAdmin
									name="avatar"
									id="photo"
									label="Photo"
									type={"file"}
									accept="image/*"
									onChange={(e) => handleChangeAvatar(e)}
								/>
							</div>
						</div>
						<div className="col-md-6 col-form-admin mb-3">
							{!!avatar && (
								<div className="image-item">
									<img
										src={
											avatar
												? getInputAvatar(avatar)
												: getAvatar(item?.avatar)
										}
										alt={"avatar"}
										loading="lazy"
										className="img-thumbnail"
										id="overlay"
									/>
								</div>
							)}
						</div>
						<div className="col-md-12 d-flex justify-content-end mb-3 pt-4">
							<div className="content-btn-form-admin">
								<button
									className="btn btn-page-title-admin w-100 d-flex align-items-center"
									disabled={isLoading}
								>
									{!!isLoading ? (
										<>
											<span
												className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
												role="status"
											></span>
											<span>Enregistrement...</span>
										</>
									) : (
										"Enregistrer"
									)}
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default AjouterUser;
